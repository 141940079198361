import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const SorryPayPeriodPage = () => {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Sorry, your pay frequency doesn't quite meet our requirements.
      </Typography>
      <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
        Pursuant to Nevada State Regulation NRS 604A.5057, we are unable to
        service loans for Nevada customers with monthly pay frequencies.
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <Typography variant="h5" component="h2">
          We'd really like to help you. Check the options below to see if any
          apply:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <DoneIcon sx={{ color: "#00db8f" }} />
            </ListItemIcon>
            <ListItemText
              primary="Did you enter your Pay Frequency correctly?"
              sx={{ color: "#838588", fontSize: "10px", fontWeight: "500" }}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default SorryPayPeriodPage;
