import React, { useEffect, useState, useCallback } from "react";
import LoanAgreement from "./LoanAgreement";
import { getLoanAgreement } from "../../services/loanPortalService";

interface LoanAgreementViewerProps {
  isAdditionalAgreement?: boolean;
}

const LoanAgreementViewer: React.FC<LoanAgreementViewerProps> = ({
  isAdditionalAgreement = false,
}) => {
  const [loanAgreementPdf, setLoanAgreementPdf] = useState<string | null>(null);
  const [loanAgreementHtml, setLoanAgreementHtml] = useState<string>("");

  const fetchAgreement = useCallback(
    async (format: "html" | "pdf") => {
      try {
        const data = await getLoanAgreement(format);
        format === "html" ? setLoanAgreementHtml(data) : setLoanAgreementPdf(data);
      } catch (error) {
        console.error("Failed to fetch loan agreement:", error);
      }
    },
    []
  );

  useEffect(() => {
    fetchAgreement("html");
  }, [fetchAgreement]);

  const agreementName = "Loan Agreement";

  return (
    <LoanAgreement
      agreementPdf={loanAgreementPdf}
      agreementHtml={loanAgreementHtml}
      onRequestAgreement={fetchAgreement}
      isAdditionalAgreement={isAdditionalAgreement}
      additionalAgreementName={agreementName}
    />
  );
};

export default LoanAgreementViewer;
