import { AppBar, Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import React, { useContext } from "react";
import Logo from "../../assets/images/logo.png";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../core/authContext";

const TopBar = () => {
  const navigate = useNavigate();
  const userDetails = useContext(AuthContext);
  const handleLogin = () => {

    if (userDetails?.isLoggedIn) {
      localStorage.clear();
    }
    navigate("/login");

  };

  return (
    <AppBar
      position="fixed"
      className="top-bar"
      sx={{ backgroundColor: "#00db8f" }}
    >
      <Toolbar
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ maxWidth: "1180px", width: "100%", mx: "auto" }}
        >
          <Grid item>
            <Box
              component="img"
              sx={{ height: 35 }}
              alt="Your logo."
              src={Logo}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                textAlign: "left",
              }}
            >
              <CallIcon sx={{ color: "white", marginRight: 1 }} />
              <Typography sx={{ color: "white", marginRight: 1 }}>
                1-800-223-5858
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "white", borderColor: "white" }}
                onClick={handleLogin}
              >
                {userDetails?.isLoggedIn ? "SIGN OUT" : "SIGN IN"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
