import { Button, Typography } from "@mui/material";
import React, { MouseEventHandler } from "react";

type IconBtnPropType = {
  handleBtnClick?: MouseEventHandler<HTMLButtonElement>;
  title: string;
  type?: "contained" | "outlined" | "text" | undefined;
  removeIcon?: boolean;
  icon?: any;
  [key: string]: any;
};

const IconBtn = ({
  handleBtnClick,
  title = "",
  type = "contained",
  removeIcon = false,
  icon,
  ...restProps
}: IconBtnPropType) => {
  return (
    
    <Button
      variant={type}
      color="primary"
      className={type === "outlined" ? "new-btn-outlined" : "new-btn"}
      onClick={handleBtnClick}
      component="button"
      sx={{ padding: "15px 40px", backgroundColor: "#00584f" }}
      {...restProps}
    >
      {icon && icon}
      <Typography sx={{marginLeft: "10px", fontSize: "18px"}}> {title} </Typography>
    </Button>
  );
};

export default IconBtn;
