import React from "react";
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const SorryAddressPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box textAlign="start" mb={3}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="bold"
          color="#00db8f"
        >
          Sorry, your address isn’t from a state we are offering new loans in.
        </Typography>
      </Box>

      <Box>
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
          mb={2}
        >
          If you currently live in Utah, Idaho, Missouri, or Nevada, you
          probably qualify. Check the options below to see if any apply:
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <DoneIcon sx={{ color: "#00db8f" }} />
            </ListItemIcon>
            <ListItemText
              primary="Is the address you provided an old address?"
              sx={{ color: "#838588", fontSize: "12px", fontWeight: "500" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneIcon sx={{ color: "#00db8f" }} />
            </ListItemIcon>
            <ListItemText
              primary="Are you a student with a temporary address in Utah, Idaho, Missouri, or Nevada? If so, we'll need the address of your current residence."
              sx={{ color: "#838588", fontSize: "10px", fontWeight: "500" }}
            />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default SorryAddressPage;
