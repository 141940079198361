import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import AmountInputField from "../../components/common/AmountField";
import Constants from "../../constants/constant";
import Dropdown from "../../components/common/Dropdown";
import IncomeSource from "./IncomeSource";
import { Address, IncomeSourceType } from "../../types/genericType";
import HelpIncomeModal from "../Modals/HelpIncomeModal";

interface StepThreeProps {
  prevStep: () => void;
  nextStep: () => void;
}

const StepThree: React.FC<StepThreeProps> = ({ prevStep, nextStep }) => {
  const [open, setOpen] = useState(false);
  const initialIncomeSource: IncomeSourceType = {
    incomeSource: "",
    employerName: "",
    addressData: {
      zip: "",
      city: "",
      state: "",
      timeAtAddress: "",
      phoneNumber: "",
      addressLine1: "",
    },
    payrollType: "",
    payFrequency: "",
    payday: "",
    nextPayDate: "",
    specificDay: "",
    firstMonthlyPayDayOfWeek: "",
    firstMonthlyPayDay: "",
    firstPayWeek: "",
    monthlyPayDayType: "",
    twicePerMonth: { first: "", second: "" },
  };

  const [formData, setFormData] = useState<{
    monthlyIncome: string;
    licenseNumber: string;
    licenseState: string;
    incomeSources: IncomeSourceType[];
  }>({
    monthlyIncome: "",
    licenseNumber: "",
    licenseState: "",
    incomeSources: [initialIncomeSource],
  });

  const [errors, setErrors] = useState<{
    monthlyIncome: string;
    licenseNumber: string;
    licenseState: string;
    incomeSources: {
      incomeSource: string;
      employerName: string;
      addressData: {
        zip: string;
        city: string;
        state: string;
        timeAtAddress: string;
        phoneNumber: string;
      };
      payrollType: string;
      payFrequency: string;
      payday: string;
      nextPayDate: string;
      specificDay: string;
      firstMonthlyPayDayOfWeek: string;
      firstMonthlyPayDay: string;
      firstPayWeek: string;
      monthlyPayDayType: string;
      twicePerMonth: { first: string; second: string };
    }[];
  }>({
    monthlyIncome: "",
    licenseNumber: "",
    licenseState: "",
    incomeSources: [
      {
        incomeSource: "",
        employerName: "",
        addressData: {
          zip: "",
          city: "",
          state: "",
          timeAtAddress: "",
          phoneNumber: "",
        },
        payrollType: "",
        payFrequency: "",
        payday: "",
        nextPayDate: "",
        specificDay: "",
        firstMonthlyPayDayOfWeek: "",
        firstMonthlyPayDay: "",
        firstPayWeek: "",
        monthlyPayDayType: "",
        twicePerMonth: { first: "", second: "" },
      },
    ],
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setFormData((prevData) => ({
          ...parsedData,
          incomeSources: parsedData.incomeSources || prevData.incomeSources,
          monthlyIncome: parsedData.monthlyIncome || prevData.monthlyIncome,
          licenseNumber: parsedData.licenseNumber || prevData.licenseNumber,
          licenseState: parsedData.licenseState || prevData.licenseState,
        }));
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      incomeSources: IncomeSourceType[];
      monthlyIncome: string;
      licenseNumber: string;
      licenseState: string;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };

  const validate = useCallback(() => {
    const newErrors = {
      monthlyIncome: formData.monthlyIncome ? "" : "Required field",
      licenseNumber: formData.licenseNumber ? "" : "Required field",
      licenseState: formData.licenseState ? "" : "Required field",
      incomeSources: formData.incomeSources.map((source) => ({
        incomeSource: source.incomeSource ? "" : "Required field",
        employerName: source.employerName ? "" : "Required field",
        addressData: {
          zip: source.addressData.zip ? "" : "Required field",
          city: source.addressData.city ? "" : "Required field",
          state: source.addressData.state ? "" : "Required field",
          timeAtAddress: source.addressData.timeAtAddress
            ? ""
            : "Required field",
          phoneNumber: source.addressData.phoneNumber ? "" : "Required field",
        },
        payrollType: source.payrollType ? "" : "Required field",
        payFrequency: source.payFrequency ? "" : "Required field",
        payday:
          source.payFrequency === "weekly" || source.payFrequency === "biWeekly"
            ? source.payday
              ? ""
              : "Required field"
            : "",
        nextPayDate:
          source.payFrequency === "biWeekly"
            ? source.nextPayDate
              ? ""
              : "Required field"
            : "",
        specificDay:
          source.payFrequency === "monthly"
            ? source.specificDay
              ? ""
              : "Required field"
            : "",
        firstMonthlyPayDayOfWeek:
          source.payFrequency === "monthly"
            ? source.firstMonthlyPayDayOfWeek
              ? ""
              : "Required field"
            : "",
        firstMonthlyPayDay:
          source.payFrequency === "monthly"
            ? source.firstMonthlyPayDay
              ? ""
              : "Required field"
            : "",
        firstPayWeek:
          source.payFrequency === "monthly"
            ? source.firstPayWeek
              ? ""
              : "Required field"
            : "",
        monthlyPayDayType:
          source.payFrequency === "monthly"
            ? source.monthlyPayDayType
              ? ""
              : "Required field"
            : "",
        twicePerMonth: {
          first:
            source.payFrequency === "monthly"
              ? source.twicePerMonth.first
                ? ""
                : "Required field"
              : "",
          second:
            source.payFrequency === "monthly"
              ? source.twicePerMonth.second
                ? ""
                : "Required field"
              : "",
        },
      })),
    };
    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => {
      if (Array.isArray(error)) {
        return error.every((srcErrors) =>
          Object.values(srcErrors).every((val) => {
            if (typeof val === "object" && val !== null) {
              return Object.values(val).every((innerVal) => !innerVal);
            }
            return !val;
          })
        );
      }
      return !error;
    });

    return isValid;
  }, [formData]);

  const handleChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleIncomeSourceChange = (
    index: number,
    field: keyof IncomeSourceType,
    value:
      | string
      | {
          week?: string;
          day?: string;
          first?: string;
          second?: string;
        }
  ) => {
    const updatedIncomeSources = formData.incomeSources.map((source, i) =>
      i === index ? { ...source, [field]: value } : source
    );
    setFormData({ ...formData, incomeSources: updatedIncomeSources });
    setErrors((prevErrors) => ({
      ...prevErrors,
      incomeSources: prevErrors.incomeSources.map((source, i) =>
        i === index ? { ...source, [field]: "" } : source
      ),
    }));
  };

  const handleAddressChange = (
    index: number,
    updatedAddress: Partial<Address> & { id: string; value: string }
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      incomeSources: prevErrors.incomeSources.map((source, i) =>
        i === index
          ? {
              ...source,
              addressData: {
                ...source.addressData,
                [updatedAddress.id]: "",
              },
            }
          : source
      ),
    }));

    setFormData((prevData) => {
      const updatedIncomeSources = prevData.incomeSources.map((source, i) =>
        i === index
          ? {
              ...source,
              addressData: {
                ...source.addressData,
                [updatedAddress.id]: updatedAddress.value,
              },
            }
          : source
      );

      return {
        ...prevData,
        incomeSources: updatedIncomeSources,
      };
    });
  };

  const handleAddIncomeSource = () => {
    if (formData.incomeSources.length < 2) {
      setFormData({
        ...formData,
        incomeSources: [...formData.incomeSources, initialIncomeSource],
      });
    }
  };

  const handleRemoveIncomeSource = (index: number) => {
    setFormData({
      ...formData,
      incomeSources: formData.incomeSources.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    updateSessionStorage({
      incomeSources: formData.incomeSources,
      monthlyIncome: formData.monthlyIncome,
      licenseNumber: formData.licenseNumber,
      licenseState: formData.licenseState,
    });
  }, [formData]);

  const handleNext = () => {
    if (validate()) {
      nextStep();
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Monthly Net Income
          </Typography>
          <AmountInputField
            label=""
            amount={formData.monthlyIncome}
            setAmount={(value: string) => handleChange("monthlyIncome", value)}
            error={!!errors.monthlyIncome}
            helperText={errors.monthlyIncome}
            showHelperText={() => {
              setOpen(true);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Driving License
          </Typography>
          <TextField
            variant="outlined"
            value={formData.licenseNumber}
            onChange={(e) => handleChange("licenseNumber", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Driving License"
            error={!!errors.licenseNumber}
            helperText={errors.licenseNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id="state"
            label=""
            value={formData.licenseState}
            options={Constants.stateOptions.map((option) => ({
              value: option.value,
              label: option.name,
            }))}
            onChange={(val) => handleChange("licenseState", val)}
            required={true}
            error={errors.licenseState}
          />
        </Grid>
        {formData.incomeSources.map((incomeSource, index) => (
          <IncomeSource
            index={index}
            key={index}
            errors={errors}
            handleIncomeSourceChange={handleIncomeSourceChange}
            handleAddressChange={handleAddressChange}
            handleRemoveIncomeSource={handleRemoveIncomeSource}
            formData={formData}
          />
        ))}
        {formData.incomeSources.length < 2 && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{
                color: "#00db8f",
                fontWeight: 500,
                cursor: "pointer",
                "&:hover": {
                  color: "#00594f",
                },
              }}
              onClick={handleAddIncomeSource}
            >
              <Typography
                sx={{
                  fontFamily: "AvenirNext-DemiBold",
                  marginLeft: "4px",
                }}
              >
                + Add Second Income Source
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <HelpIncomeModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
};

export default StepThree;
