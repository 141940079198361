import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

interface SorryJobHistoryPageProps {
  minimumTimeAtEmployerRequirement?: number;
  onModelUpdate?: (model: { timeAtEmploymentException: string }) => void;
}

const SorryJobHistoryPage: React.FC<SorryJobHistoryPageProps> = ({
  minimumTimeAtEmployerRequirement = 3,
}) => {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        {" "}
        Sorry, your job history doesn't meet our minimum requirements yet.
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
        mb={2}
      >
        You must have worked at your current job for more than{" "}
        {minimumTimeAtEmployerRequirement} months to qualify.
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
        >
          We'd really like to help you. Check the options below to see if any
          apply:
        </Typography>
        <List>
          {false && (
            <ListItem>
              <ListItemIcon>
                <DoneIcon sx={{ color: "#00db8f" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    Did you recently start a new job, but it’s in a similar
                    industry? If so, we can work with you,{" "}
                    <Link href="#/apply">click here</Link>.
                  </>
                }
                sx={{ color: "#838588", fontSize: "10px", fontWeight: "500" }}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              <DoneIcon sx={{ color: "#00db8f" }} />
            </ListItemIcon>
            <ListItemText
              primary="Double check that the information you put is correct."
              sx={{ color: "#838588", fontSize: "10px", fontWeight: "500" }}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default SorryJobHistoryPage;
