import axios, { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";

export const login = async (
  username: string,
  password: string,
  verification: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${apiEndPoints.login}`,
      {
        username,
        password,
        verification,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (username: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${apiEndPoints.forgotPassword}`,
      {
        username,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
