import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

interface SorryAutoDenialPageProps {
  redirectUrl?: string;
}

const SorryAutoDenialPage: React.FC<SorryAutoDenialPageProps> = ({
  redirectUrl,
}) => {
  useEffect(() => {
    if (redirectUrl) {
      const timer = setTimeout(() => {
        window.location.href = redirectUrl;
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [redirectUrl]);

  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        We're Sorry!
      </Typography>

      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="bold"
          color="#00db8f"
        >
          Unfortunately, your loan application didn't meet our minimum
          requirements.
        </Typography>
        {redirectUrl && (
          <Typography
            sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
            mt={2}
          >
            The good news is that we have a partner who can help you find a
            lender that better fits your situation. You will be automatically
            redirected to our partner site in 5 seconds.
          </Typography>
        )}
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
          mb={2}
        >
          Thanks for applying!
        </Typography>
      </Box>
    </Box>
  );
};

export default SorryAutoDenialPage;
