import React from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Dropdown from "../../components/common/Dropdown";
import AddressForm from "../../components/addressForm/AddressForm";
import Constants from "../../constants/constant";
import { calculateNextPayDates, formatDateToMMDD } from "../../utils/dateUtils";
import { ClearIcon } from "@mui/x-date-pickers";

interface IncomeSourceProps {
  index: number;
  formData: any;
  errors: any;
  handleIncomeSourceChange: Function;
  handleAddressChange: (index: number, updatedAddress: any) => void;
  handleRemoveIncomeSource: (index: number) => void;
}

const IncomeSource = ({
  index,
  formData,
  errors,
  handleIncomeSourceChange,
  handleAddressChange,
  handleRemoveIncomeSource,
}: IncomeSourceProps) => {

  const renderExtraFields = (payFrequency: string, index: number) => {
    switch (payFrequency) {
      case "weekly":
        return (
          <Grid item xs={6}>
            <Dropdown
              id="payday"
              label="Payday"
              value={formData.incomeSources[index].payday}
              options={Constants.dayOfWeekOptions.map((option) => ({
                value: option.value,
                label: option.name,
              }))}
              onChange={(val) => handleIncomeSourceChange(index, "payday", val)}
              required={true}
              error={errors.incomeSources[index]?.payday}
            />
          </Grid>
        );
      case "biWeekly":
        return (
          <>
            <Grid item xs={6}>
              <Dropdown
                id="payday"
                label="Payday"
                value={formData.incomeSources[index].payday}
                options={Constants.dayOfWeekOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(val) =>
                  handleIncomeSourceChange(index, "payday", val)
                }
                required={true}
                error={errors.incomeSources[index]?.payday}
              />
            </Grid>
            {formData.incomeSources[index].payday && (
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                    color: "#838588",
                  }}
                >
                  Next Pay Date
                </Typography>
                <RadioGroup
                  row
                  value={formData.incomeSources[index].nextPayDate}
                  onChange={(e) =>
                    handleIncomeSourceChange(
                      index,
                      "nextPayDate",
                      e.target.value
                    )
                  }
                  sx={{ display: "flex" }}
                >
                  {calculateNextPayDates(
                    formData.incomeSources[index].payday
                  ).map((date, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={date}
                      control={
                        <Radio
                          sx={{
                            "&.MuiRadio-root": {
                              display: "none",
                            },
                          }}
                        />
                      }
                      label={formatDateToMMDD(new Date(date))}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                        padding: 1,
                        cursor: "pointer",
                        marginLeft: 1,
                        textAlign: "center",
                        color: "#333",
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                        border:
                          date === formData.incomeSources[index].nextPayDate
                            ? "1px solid #00db8f"
                            : "",
                        backgroundColor:
                          date === formData.incomeSources[index].nextPayDate
                            ? "#e0e0e0"
                            : "white",
                        borderRadius: "4px",
                      }}
                    />
                  ))}
                </RadioGroup>
                {errors.incomeSources[index]?.nextPayDate && (
                  <Typography color="error" variant="caption">
                    {errors.incomeSources[index]?.nextPayDate}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        );
      case "monthly":
        return (
          <>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                I get paid on
              </Typography>
              <RadioGroup
                row
                value={formData.incomeSources[index].monthlyPayDayType}
                onChange={(e) =>
                  handleIncomeSourceChange(
                    index,
                    "monthlyPayDayType",
                    e.target.value
                  )
                }
                sx={{ display: "flex" }}
              >
                <FormControlLabel
                  value={"Day"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Specific Day"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      formData.incomeSources[index].monthlyPayDayType === "Day"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      formData.incomeSources[index].monthlyPayDayType === "Day"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
                <FormControlLabel
                  value={"DayOfWeek"}
                  control={
                    <Radio
                      sx={{
                        "&.MuiRadio-root": {
                          display: "none",
                        },
                      }}
                    />
                  }
                  label="Day of Week"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "40px",
                    padding: 1,
                    cursor: "pointer",
                    marginLeft: 1,
                    textAlign: "center",
                    color: "#333",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    border:
                      formData.incomeSources[index].monthlyPayDayType ===
                      "DayOfWeek"
                        ? "1px solid #00db8f"
                        : "",
                    backgroundColor:
                      formData.incomeSources[index].monthlyPayDayType ===
                      "DayOfWeek"
                        ? "#e0e0e0"
                        : "white",
                    borderRadius: "4px",
                  }}
                />
              </RadioGroup>
              {errors.incomeSources[index]?.monthlyPayDayType && (
                <Typography color="error" variant="caption">
                  {errors.incomeSources[index]?.monthlyPayDayType}
                </Typography>
              )}
            </Grid>

            {formData.incomeSources[index].monthlyPayDayType === "DayOfWeek" ? (
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Dropdown
                      id="week"
                      label="Pay Day of Month"
                      value={formData.incomeSources[index].firstPayWeek}
                      options={Constants.weekOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstPayWeek",
                          formData.incomeSources[index].firstPayWeek
                        )
                      }
                      required={true}
                      error={errors.incomeSources[index]?.firstPayWeek}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Dropdown
                      id="day"
                      label=""
                      value={
                        formData.incomeSources[index].firstMonthlyPayDayOfWeek
                      }
                      options={Constants.dayOfWeekOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstMonthlyPayDayOfWeek",
                          formData.incomeSources[index].firstMonthlyPayDayOfWeek
                        )
                      }
                      required={true}
                      error={
                        errors.incomeSources[index]?.firstMonthlyPayDayOfWeek
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Dropdown
                      id="week"
                      label="Pay Day of Month"
                      value={formData.incomeSources[index].firstMonthlyPayDay}
                      options={Constants.dayOfMonthOptions.map((option) => ({
                        value: option.value,
                        label: option.name,
                      }))}
                      onChange={(val) =>
                        handleIncomeSourceChange(
                          index,
                          "firstMonthlyPayDay",
                          formData.incomeSources[index].firstMonthlyPayDay
                        )
                      }
                      required={true}
                      error={errors.incomeSources[index]?.firstMonthlyPayDay}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        marginTop: "30px",
                        color: "#838588",
                      }}
                    >
                      of month
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        );
      case "twiceMonthly":
        return (
          <>
            <Grid item xs={6}>
              <Dropdown
                id="first"
                label="First"
                value={formData.incomeSources[index].twicePerMonth.first}
                options={Constants.weekOfMonthOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(val) =>
                  handleIncomeSourceChange(index, "twicePerMonth", {
                    ...formData.incomeSources[index].twicePerMonth,
                    first: val,
                  })
                }
                required={true}
                error={errors.incomeSources[index]?.twicePerMonth?.first}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                id="second"
                label="Second"
                value={formData.incomeSources[index].twicePerMonth.second}
                options={Constants.weekOfMonthOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(val) =>
                  handleIncomeSourceChange(index, "twicePerMonth", {
                    ...formData.incomeSources[index].twicePerMonth,
                    second: val,
                  })
                }
                required={true}
                error={errors.incomeSources[index]?.twicePerMonth?.second}
              />
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment key={index}>
      {index === 1 && (
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{
              color: "#00db8f",
              fontWeight: 500,
              cursor: "pointer",
              "&:hover": {
                color: "#00594f",
              },
            }}
            onClick={() => handleRemoveIncomeSource(index)}
          >
            <ClearIcon fontSize="small" />
            <Typography
              sx={{
                fontFamily: "AvenirNext-DemiBold",
                marginLeft: "4px",
              }}
            >
              Remove Income Source
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
      <Dropdown
          id="incomeSource"
          label="Income Source"
          value={formData.incomeSources[index].incomeSource}
          options={Constants.incomeTypeOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) =>
            handleIncomeSourceChange(index, "incomeSource", val)
          }
          required={true}
          error={errors.incomeSources[index]?.incomeSource}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            paddingLeft: "8px",
            marginBottom: "5px",
            color: "#838588",
          }}
        >
          Employer Name
        </Typography>
        <TextField
          variant="outlined"
          value={formData.incomeSources[index].employerName}
          onChange={(e) =>
            handleIncomeSourceChange(index, "employerName", e.target.value)
          }
          fullWidth
          size="small"
          placeholder="Enter Employer Name"
          error={!!errors.incomeSources[index]?.employerName}
        />
        {errors.incomeSources[index]?.employerName && (
          <Typography color="error" variant="caption">
            {errors.incomeSources[index]?.employerName}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            paddingLeft: "8px",
            color: "#838588",
          }}
        >
          Employer Address
        </Typography>
      </Grid>
      <AddressForm
        value={formData.incomeSources[index].addressData}
        onChange={(updatedAddress) =>
          handleAddressChange(index, updatedAddress)
        }
        required
        errors={errors.incomeSources[index]?.addressData}
        detailAddress
        timeAtAddressLabel="Time at Employer"
        phoneNumberLabel="Employer Phone"
      />
      <Grid item xs={6}>
        <Dropdown
          id="payrollType"
          label="Payroll Information"
          value={formData.incomeSources[index].payrollType}
          options={Constants.payrollTypeOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) =>
            handleIncomeSourceChange(index, "payrollType", val)
          }
          required={true}
          error={errors.incomeSources[index]?.payrollType}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          id="payFrequency"
          label="Pay Period"
          value={formData.incomeSources[index].payFrequency}
          options={Constants.payFrequencyOptions.map((option) => ({
            value: option.value,
            label: option.name,
          }))}
          onChange={(val) =>
            handleIncomeSourceChange(index, "payFrequency", val)
          }
          required={true}
          error={errors.incomeSources[index]?.payFrequency}
        />
      </Grid>
      {renderExtraFields(formData.incomeSources[index].payFrequency, index)}
    </React.Fragment>
  );
};

export default IncomeSource;
