import React from "react";
import { Box, Typography } from "@mui/material";

interface SorryAgeTooLowPageProps {
  defaultContent?: React.ReactNode;
}

const SorryAgeTooLowPage: React.FC<SorryAgeTooLowPageProps> = ({
  defaultContent,
}) => {
  return (
    <Box sx={{ textAlign: "center", padding: "2rem" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Sorry, we won't be able to offer you a loan right now.
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
        mb={2}
      >
        You have to be at least 18 years old to qualify for a loan
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>{defaultContent}</Box>
    </Box>
  );
};

export default SorryAgeTooLowPage;
