import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import Constants from "../../constants/constant";
import { ModalProps } from "../../types/genericType";

const ConsentModal = (props: ModalProps) => {
  const { open, handleClose } = props;
  const info = Constants.infoLiftCredit;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "900px",
          height: "90vh",
          overflowY: "auto",
          margin: "auto",
          padding: 4,
          marginTop: "4%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "400",
            textAlign: "center",
            color: "black",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Consent to Electronic Communications
        </Typography>
        <Box sx={{ color: "#838588", padding: "30px 50px" }}>
          <p>
            You consent to receive disclosures from Lift Credit, LLC (“Lift”)
            and its agents electronically through this website, by email, and by
            text message (“Electronic Messages”). By consenting to the
            electronic delivery of disclosures, you agree that we may provide
            all communications concerning our decisions on your application, the
            terms of any loan that may be provided to you, our privacy policy,
            the status and history of your loan, and any further disclosures
            required by federal or state law (“Disclosures”) through Electronic
            Messages. Further, you acknowledge and agree that: (i) Lift may send
            you Electronic Messages using an auto telephone dialing system or
            automatic texting system, and (ii) that message and data rates may
            apply to your receipt and sending of text messages to us and that
            you are responsible for all such rates.
          </p>

          <p>
            The Disclosures may include disclosures pursuant to: (1) the federal
            Equal Credit Opportunity Act and Regulation B; (2) the federal Fair
            Credit Reporting Act; (3) the federal Truth in Lending Act and
            Regulation Z; (4) the federal Electronic Funds Transfer Act and
            Regulation E; (5) the federal Gramm-Leach-Bliley Act; (6) the
            federal Telephone Consumer Protection Act; and (7) any other
            applicable federal, state or local law or regulation. Your consent
            applies not only to the application and any resulting loan, but also
            to any customer service requests, payment plans, delinquency
            notifications, and any other ancillary agreement or communication
            related to your loan and any future loans from us.
          </p>

          <p>
            To receive Disclosures via Electronic Messages, you must have: (1) a
            personal computer with Internet access; (2) a widely-used,
            recent-generation web browser (for example, Chrome, Internet
            Explorer, Safari or Firefox); (3) a recent version of Adobe Reader;
            (4) the email address specified on your application (or a new email
            address you subsequently provide us); and (5), either a printer,
            hard drive, or other storage device, and (6) a mobile telephone, if
            applicable to receive text messages. You understand that Disclosures
            may be sent to you by emails containing attachments in .pdf format,
            emails containing links to documents in .html or other
            browser-viewable format, or made available to you in the member
            section of our web site. We anticipate that any emails we send you
            may have file sizes up to 5mb (but not larger). You should make sure
            that any spam filter you use is set to allow receipt of messages
            from {info.email}. If the information you have supplied us for
            contacting you through Electronic Messages changes, please notify us
            of your new contact information by sending an email to {info.email},
            or by calling Customer Service at {info.phone}. By continuing with
            the application process, you represent that you have the hardware,
            software, email address, email capacities, and mobile phone number
            described above to receive Electronic Messages.
          </p>

          <p>
            Prior to submission of your application and execution of the loan
            documents, you may withdraw your consent to receipt of Electronic
            Messages by exiting this website. To withdraw your consent after
            this time, send an email, call Customer Service at {info.phone}, or
            respond STOP to any text message. Include your name, address, and
            loan number in any such request. You have the option to receive any
            information that we have provided electronically in paper form at no
            cost to you.
          </p>

          <p>
            You authorize us (1) to obtain consumer reports on you from consumer
            reporting agencies and (2) promise that in information that you
            supply us is true and accurate. After you complete this portion of
            the application we will let you know whether you might be eligible
            for a loan. If you are, we will ask you for additional information
            and after you supply it we will notify you of our decision.
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ConsentModal;
