import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';

interface SorryIncomeTooLowPageProps {
    minimumIncomeRequirement?: number;
    lendingPartnerStatus?: string;
    onModelUpdate?: (data: { cosigner: string }) => void;
}

const SorryIncomeTooLowPage: React.FC<SorryIncomeTooLowPageProps> = ({
    minimumIncomeRequirement = 1250,
    lendingPartnerStatus,
    onModelUpdate,
}) => {
    const handleSetCosigner = () => {
        onModelUpdate?.({ cosigner: 'true' });
    };

    const formattedIncome = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(minimumIncomeRequirement);

    return (
        <Box sx={{ padding: '2rem', textAlign: 'center' }}>
           <Typography
          variant="h5"
          component="h5"
          fontWeight="bold"
          color="#00db8f"
        >
                Sorry, your income doesn't quite meet our requirements.
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: '1rem' }}>
                The minimum income requirement for a loan is {formattedIncome} net per month.
            </Typography>
            {lendingPartnerStatus && lendingPartnerStatus !== 'sold' && (
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', marginTop: '0.5rem' }}>
                    We were unable to connect you with a lender in our partner network.
                </Typography>
            )}
            <Box sx={{ marginTop: '2rem' }}>
                <Typography variant="h5" component="h2">
                    We'd really like to help you. Check the options below to see if any apply:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon sx={{ color: "#00db8f" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Did you calculate your <b>TOTAL net monthly income</b>{' '}
                                    <Link href="#" sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                                        <InfoIcon fontSize="small" />
                                    </Link>{' '}
                                    correctly?
                                </>
                            }
                            secondary="Net monthly income is your actual take-home pay after taxes. If you get paid by check(s) it’s the amount the check is written for."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon sx={{ color: "#00db8f" }} />
                        </ListItemIcon>
                        <ListItemText primary="Did you include ALL of your income? Don't forget to add income from: Social Security, Child Support, Unemployment, a second job, Disability, or Alimony." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DoneIcon sx={{ color: "#00db8f" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Could you add a <b>co-signer</b>{' '}
                                    <Link href="#" onClick={handleSetCosigner} sx={{ cursor: 'pointer' }}>
                                        click here
                                    </Link>
                                    . We'll need to add their information later in the application.
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </Box>
        </Box>
    );
};

export default SorryIncomeTooLowPage;
