import React, { useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import typography from './typography';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { palette } from './palette';

interface Props {
  children: React.ReactNode;
}

const ThemeConfig = ({ children }: Props) => {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
    }),
    [],
  );

  // @ts-ignore
  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
