/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Box, Typography, Link } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./LoanAgreement.css";

interface LoanAgreementProps {
  agreementHtml: string;
  agreementPdf: string | null;
  onRequestAgreement: (format: "html" | "pdf") => void;
  isAdditionalAgreement: boolean;
  additionalAgreementName: string;
}

const LoanAgreement: React.FC<LoanAgreementProps> = ({
  agreementHtml,
  agreementPdf,
  onRequestAgreement,
  isAdditionalAgreement,
}) => {
  const [formatDisplay, setFormatDisplay] = useState<"html" | "pdf">("html");
  const [pdfDownloadRequested, setPdfDownloadRequested] = useState(false);
  const pdfDownloadLinkRef = useRef<HTMLAnchorElement>(null);

  const agreementHtmlContent = { __html: agreementHtml };

  useEffect(() => {
    if (pdfDownloadRequested && !agreementPdf) {
      onRequestAgreement("pdf");
    }
  }, [pdfDownloadRequested, agreementPdf, onRequestAgreement]);

  useEffect(() => {
    if (agreementPdf && pdfDownloadRequested) {
      pdfDownloadLinkRef.current?.click();
      setPdfDownloadRequested(false);
    }
  }, [agreementPdf, pdfDownloadRequested]);

  useEffect(() => {
    const removeAgreementTags = () => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = agreementHtml;
      const scriptElements = tempDiv.querySelectorAll("script");
      scriptElements.forEach((script) =>
        document.body.appendChild(script.cloneNode(true))
      );
    };

    if (agreementHtml) removeAgreementTags();
  }, [agreementHtml]);

  const changeFormat = useCallback(
    (format: "html" | "pdf") => {
      setFormatDisplay(format);
      if (format === "html" && !agreementHtml) {
        onRequestAgreement("html");
      }
      if (format === "pdf" && !agreementPdf) {
        onRequestAgreement("pdf");
      }
    },
    [agreementHtml, agreementPdf, onRequestAgreement]
  );

  const handleDownloadPDF = () => {
    setPdfDownloadRequested(true);
    if (!agreementPdf) onRequestAgreement("pdf");
  };

  return (
    <Box>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "24px", textAlign: "center", fontWeight: "500", mb: 1 }}
      >
        Loan Agreement
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "12px", textAlign: "center", mb: 2 }}
      >
        Read the terms of your current loan
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "12px" }}
      >
        {isAdditionalAgreement
          ? "To view the full document, please scroll down."
          : "Remember, you can pay off your loan at any time! There are never pre-payment penalties. To view the full document, please scroll down."}
      </Typography>

      <Button
        variant="contained"
        sx={{
          backgroundColor: "#004d40",
          color: "#fff",
          fontSize: "14px",
          textTransform: "capitalize",
          float: "right",
          ":hover": {
            backgroundColor: "#004d40"
          },
          mt: 2,
          mb: 3,
        }}
        onClick={handleDownloadPDF}
      >
        Download PDF <ChevronRightIcon />
      </Button>

      <a
        ref={pdfDownloadLinkRef}
        href={`data:application/pdf;base64,${agreementPdf}`}
        download="loanAgreement.pdf"
        style={{ display: "none" }}
      >
        PDF Download Link
      </a>

      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "16px", textAlign: "left", fontWeight: "500", mt: 7 }}
      >
        Loan Agreement
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        {formatDisplay === "html" && agreementHtml && (
          <div
            className="loan-agreement pd-10 emphasized"
            dangerouslySetInnerHTML={agreementHtmlContent}
          />
        )}
        {formatDisplay === "pdf" && agreementPdf && (
          <div className="loan-agreement noscroll nopad emphasized">
            <object
              data={`data:application/pdf;base64,${agreementPdf}`}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          </div>
        )}

        <Box className="switch" sx={{ marginTop: 1 }}>
          {formatDisplay === "html" && agreementHtml && (
            <Link
              onClick={() => changeFormat("pdf")}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: "#00db8f",
              }}
            >
              View as PDF
            </Link>
          )}
          {formatDisplay === "pdf" && agreementPdf && (
            <Box>
              Having trouble viewing this?{" "}
              <Link
                onClick={() => changeFormat("html")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#00db8f",
                }}
              >
                View as HTML
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoanAgreement;
