import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const PaidLoan = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "bold" }}>
          Loan Status: Paid in Full
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            <Typography variant="h6" sx={{ color: "#333" }}>
              0
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Remaining Loan Balance
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              99%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "6rem",
                fontWeight: "bold",
              }}
            >
              Interest Rate
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#004d40", color: "#fff" }}
              onClick={() => navigate("/agreement")}
            >
              LOAN AGREEMENT <ChevronRightIcon />
            </Button>
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: "#838588",
                marginTop: "0.5rem",
                fontStyle: "italic",
              }}
            >
              Loan Number: 168764
            </Typography>
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        <Grid item xs={12} sm={5}>
          <Box sx={{ padding: "2rem" }}>
            <Typography variant="h6" sx={{ color: "#333" }}>
              0
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              *Total Reward Points
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              0
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Total Reward Value
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              0
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1.7rem",
                fontWeight: "bold",
              }}
            >
              On-Time Payments
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#004d40", color: "#fff" }}
              onClick={() => navigate("/rewards")}
            >
              REDEEM POINTS <ChevronRightIcon />
            </Button>
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: "#838588",
                marginTop: "0.5rem",
                fontStyle: "italic",
              }}
            >
              *2500 points minimum to redeem
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaidLoan;
