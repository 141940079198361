import Constants from "../constants/constant";

export const formatDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
};

export const formatDateToMMDD = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
};

export const calculateNextPayDates = (selectedDayOfWeek: string) => {
  const today = new Date();

  const targetDayIndex = Constants.dayOfWeekOptions.findIndex(
    (option) => option.value === selectedDayOfWeek
  );

  const nextDates = [];

  for (let i = 0; i < 2; i++) {
    const nextPayDate = new Date(today);
    let dayDiff = targetDayIndex - nextPayDate.getDay();

    if (dayDiff <= 0) {
      dayDiff += 7;
    }

    nextPayDate.setDate(today.getDate() + dayDiff + 7 * i);
    nextDates.push(formatDate(nextPayDate));
  }

  return nextDates;
};
