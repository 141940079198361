import { Box, Typography } from "@mui/material";
import React from "react";
import FooterLogo from "../../assets/images/footer_logo.png";

const Footer = () => {
  const currentYear = new Date(Date.now()).getFullYear();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        backgroundColor: "#f4f4f4",
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        sx={{ height: 35, marginBottom: "10px" }}
        alt="Footer logo."
        src={FooterLogo}
      />
      <Typography
        sx={{ fontSize: "14px", color: "#838588", fontStyle: "italic" }}
      >
        This website and its content is copyright of Lift Credit &copy; Lift
        Credit
        {` ${currentYear}`}&#46; All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
