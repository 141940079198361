import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalProps } from "../../types/genericType";

const HelpIncomeModal = (props: ModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "900px",
          height: "auto",
          overflowY: "auto",
          margin: "auto",
          padding: 4,
          marginTop: "4%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "400",
            textAlign: "center",
            color: "black",
            marginTop: "40px",
          }}
          gutterBottom
        >
          What is Monthly Net Income?
        </Typography>
        <Box sx={{ color: "#838588", padding: "30px 50px" }}>
          <p>
            Monthly Net Income is your income each month after all taxes and
            deductions. If you're paid by check, it's the amount shown on your
            check.
          </p>
          <p>
            Alimony, child support, or separation maintenance income need not be
            revealed if you do not wish to have it considered as a basis for
            repaying this obligation.
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default HelpIncomeModal;
