import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const SorryActiveMilitaryPage: React.FC = () => {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Sorry, we can't loan to active military.
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
        mb={2}
      >
        Our lending license doesn't permit us to lend to active military or
        their dependents
      </Typography>

      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
          mb={2}
        >
          We cannot lend to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Regular or reserve member of the Army, Navy, Marine Corps, Air Force, or Coast Guard, serving on active duty under a call or order that does not specify a period of 30 days or fewer." />
          </ListItem>
          <ListItem>
            <Typography
              variant="h6"
              component="h4"
              sx={{ textAlign: "center", marginTop: "1rem" }}
            >
              OR
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText primary="Dependents of a member of the Armed Forces on active duty as described above, because I am the member's spouse, the member's child under the age of eighteen years old, or I am an individual for whom the member provided more than one-half of my financial support for 180 days immediately preceding today's date." />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default SorryActiveMilitaryPage;
