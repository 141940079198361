import { CircularProgress, Typography } from "@mui/material";
import React from "react";

type Props = {
  message?: string;
  height?: string;
};

const Loader = ({ message = "Loading...", height = "50vh" }: Props) => {
  return (
    <div style={{ ...styles.loaderContainer, height }}>
      <CircularProgress style={styles.loader} />
      <Typography style={styles.message}>{message}</Typography>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    color: "#00db8f",
  },
  message: {
    marginTop: "16px",
    color: "#00db8f",
  },
};

export default Loader;
