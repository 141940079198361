import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckImage from "../../assets/images/check.png";
import { ModalProps } from "../../types/genericType";

const SampleCheckModal = (props: ModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "900px",
          height: "auto",
          overflowY: "auto",
          margin: "auto",
          padding: 4,
          marginTop: "4%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "400",
            textAlign: "center",
            color: "black",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Sample Check
        </Typography>
        <Box
          sx={{
            color: "#838588",
            padding: "30px 50px",
            alignContent: "center",
          }}
        >
          <Box component="img" alt="Footer logo." width="100%" src={CheckImage} />
        </Box>
      </Paper>
    </Modal>
  );
};

export default SampleCheckModal;
