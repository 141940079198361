import React, { useEffect, useState } from "react";
import { Button, Typography, CircularProgress, Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./CartPage.css";
import {
  getRewardsOrderSummary,
  placeRewardOrder,
} from "../../services/rewardsService";
import SummaryItem from "./SummaryItem";
import ErrorComp from "../../components/errorComp/ErrorComp";

const CartPage = () => {
  const [summaryData, setSummaryData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(true);

  const handleRemoveItem = (sku: string) => {
    const cartItems = JSON.parse(localStorage.getItem("rewardCart") || "[]");
    const updatedCart = cartItems.filter((item: string) => item !== sku);
    localStorage.setItem("rewardCart", JSON.stringify(updatedCart));
    fetchOrderSummary();
  };

  const rewards = JSON.parse(localStorage.getItem("rewards") || "[]");

  const fetchOrderSummary = async () => {
    const cartItems = JSON.parse(localStorage.getItem("rewardCart") || "[]");
    setLoading(true);
    setError(null);
    const response = await getRewardsOrderSummary(cartItems);
    if (response.resource) {
      setIsErrorVisible(true);
      setSummaryData(response.resource);
      setLoading(false);
    } else {
      setError("Error fetching order summary");
      setLoading(false);
    }
  };

  const updateCart = (prevSku: string, newSku: string) => {
    const cartItems = JSON.parse(localStorage.getItem("rewardCart") || "[]");
    const updatedCart = cartItems.map((item: string) =>
      item === prevSku ? newSku : item
    );
    localStorage.setItem("rewardCart", JSON.stringify(updatedCart));
    fetchOrderSummary();
  };

  const placeOrder = async () => {
    if (!summaryData?.validationResult?.allow) {
      setIsErrorVisible(true);
      return;
    }
    const cartItems = JSON.parse(localStorage.getItem("rewardCart") || "[]");
    const response = await placeRewardOrder({ skus: cartItems });
    if (response.resource) {
      localStorage.setItem("rewardCart", "[]");
      setSummaryData(null);
    } else {
      setError("Error placing order");
    }
  };

  useEffect(() => {
    fetchOrderSummary();
  }, []);

  const getSelectedReward = (sku: string) => {
    const reward = rewards.find((reward: any) =>
      reward.options.some((option: any) => option.sku === sku)
    );
    return reward;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ fontSize: "14px", color: "#838588" }}>
        CART
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#00db8f" }}>
        Redeem Points
      </Typography>
      {isErrorVisible && summaryData?.result?.error && (
        <ErrorComp
          message={summaryData?.result?.message}
          details={summaryData?.result?.details}
          onClose={() => setIsErrorVisible(false)}
          detailsRoute="/sorry"
        />
      )}
      <div className="cart-page">
        <div className="cart-section">
          {summaryData && (
            <div className="order-details">
              {summaryData.items.map((item: any, index: number) => (
                <SummaryItem
                  value={item.sku}
                  key={`${item.sku}-${index}`}
                  item={item}
                  getSelectedReward={getSelectedReward}
                  onRemove={handleRemoveItem}
                  updateCart={updateCart}
                />
              ))}
            </div>
          )}

          <Typography
            sx={{ fontWeight: "bold", color: "#5a5a5b", fontSize: "14px" }}
          >
            Confirmation Email Address:
          </Typography>
          <Typography sx={{ color: "#5a5a5b", fontSize: "12px" }}>
            {summaryData?.recipientEmail}
          </Typography>
        </div>

        <div className="summary-section">
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#00db8f" }}
          >
            Review Your Order
          </Typography>

          {summaryData && (
            <div className="order-details">
              {summaryData.items.map((item: any, index: number) => (
                <div className="detail-row" key={`${item.sku}-${index}`}>
                  <span>{item.description}</span>
                  <span>
                    <span className="points">{item.points} </span>PTS
                  </span>
                </div>
              ))}

              <hr />
              <div className="detail-row">
                <span>Total Rewards Points</span>
                <span>
                  <span className="points">{summaryData.totalPoints} </span>PTS
                </span>
              </div>
              <div className="detail-row">
                <span>Points Redeeming</span>
                <span>
                  <span className="points">{summaryData.redeemedPoints} </span>
                  PTS
                </span>
              </div>
              <hr />
              <div className="detail-row">
                <span>Points Remaining</span>
                <span>
                  <span className="remaining-pts">
                    {summaryData.remainingPoints}{" "}
                  </span>
                  PTS
                </span>
              </div>
              <hr />
            </div>
          )}

          <Typography
            sx={{ fontSize: "12px", marginTop: "10px", color: "#838588" }}
          >
            Your points will be used instantly and cannot be credited back once
            used. By selecting "Confirm & Submit" you are authorizing this
            redemption and agreeing to the merchant terms and conditions.
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#004d40",
              color: "#fff",
              marginTop: "20px",
              width: "100%",
              textTransform: "capitalize",
            }}
            endIcon={<ChevronRightIcon />}
            onClick={placeOrder}
          >
            Confirm & Submit
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default CartPage;
