import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { ErrorDetails } from "../../types/genericType";

interface SorryErrorPageProps {
  error?: ErrorDetails;
  showDetails?: boolean;
}

const SorryErrorPage: React.FC<SorryErrorPageProps> = ({
  error,
  showDetails,
}) => {
  const handleCopyError = () => {
    navigator.clipboard
      .writeText(error?.exception?.exceptionDetails || "")
      .then(() => {
        console.log("Error details copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy error details: ", err);
      });
  };

  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Uh oh! Something went wrong!
      </Typography>
      <Typography sx={{ color: "#838588", fontSize: "12px" }} mb={2}>
        An error occurred while trying to perform a request. Here are the
        details:
      </Typography>

      <Box sx={{ marginTop: "2rem", textAlign: "left" }}>
        {error && (
          <Box>
            <Typography
              sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
              mb={2}
            >
              <b>Error:</b> {error.message}
            </Typography>
            {error.details && (
              <Typography
                sx={{ color: "#838588", fontSize: "14px" }}
                mb={2}
              >
                Details: {error.details}
              </Typography>
            )}
            {showDetails && error.exception && (
              <Box>
                <Typography variant="h6" component="h5">
                  <b>Exception Details:</b>
                </Typography>
                <Box
                  sx={{
                    margin: "1rem 0",
                    padding: "1rem",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                    {error.exception.exceptionDetails}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ marginTop: "1rem" }}
                  onClick={handleCopyError}
                >
                  Copy Error
                </Button>
              </Box>
            )}
          </Box>
        )}
        <Typography
          sx={{ color: "#838588", fontSize: "14px" }}
          mb={2}
        >
          Click back and try submitting your request again.
        </Typography>
      </Box>
    </Box>
  );
};

export default SorryErrorPage;
