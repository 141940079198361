import { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import axiosInstance from "../core/axios";
import { Address, LeadsRequest } from "../types/genericType";

export interface LoanApplication {
  verification: string;
  firstName: string;
  lastName: string;
  address: Address;
  email: string;
  monthlyIncome: string;
  dateOfBirth: string;
  phoneNumber: string;
  consentToMarketing: boolean;
  bankVerificationRequestResult: string;
  socialSecurityNumber: string;
  advertisingMethod: string;
  advertisingMethodText: null;
  licenseNumber: string;
  licenseState: string;
  timeAtAddress: string;
  optInToMarketingMessage: boolean;
  optInToReceiveTextMessage: boolean;
  incomeSources: IncomeSource[];
  bankAccounts: BankAccount[];
  bankCard: BankCard;
}

interface IncomeSource {
  monthlyIncome: string;
  incomeType: string;
  employer: string;
  timeAtEmployment: string;
  payDay: PayDay;
  employerAddress: EmployerAddress;
  workPhoneNumber: string;
}

interface PayDay {
  payrollType: string;
  payFrequency: string;
  payDay: string;
}

interface EmployerAddress {
  zip: string;
  city: string;
  state: string;
}

interface BankAccount {
  id: number;
  bankRoutingNumber: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountType: string;
}

interface BankCard {
  nameOnCard: string;
  cardNumber: string;
  expiration: string;
  billingAddressSameAsPrimary: boolean;
  billingAddress: Address;
}

const assignApplicationDetails = (data: LoanApplication) => {
  return {
    verification: data.verification,
    firstName: data.firstName,
    lastName: data.lastName,
    address: data.address,
    email: data.email,
    monthlyIncome: data.monthlyIncome,
    dateOfBirth: data.dateOfBirth,
    phoneNumber: data.phoneNumber,
    consentToMarketing: data.consentToMarketing,
    bankVerificationRequestResult: data.bankVerificationRequestResult,
    socialSecurityNumber: data.socialSecurityNumber,
    advertisingMethod: data.advertisingMethod,
    advertisingMethodText: data.advertisingMethodText,
    licenseNumber: data.licenseNumber,
    licenseState: data.licenseState,
    timeAtAddress: data.timeAtAddress,
    incomeSources: data.incomeSources,
    bankAccounts: data.bankAccounts,
    bankCard: data.bankCard,
  };
};

export const createLead = async (leadData: LeadsRequest): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.leads,
      leadData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const submitLoanApplication = async (
  data: LoanApplication
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.loanApplications,
      assignApplicationDetails(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
