import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Constants from "../../constants/constant";

type RecaptchaProps = {
  setCaptchaToken: Function;
};

const Recaptcha = (props: RecaptchaProps) => {
  const { setCaptchaToken } = props;
  const handleRecaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <ReCAPTCHA
      onChange={handleRecaptchaChange}
      sitekey={Constants.recaptchaSiteKey}
    />
  );
};

export default Recaptcha;
