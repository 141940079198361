import React, { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Box,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface StepTwoProps {
  nextStep: () => void;
  prevStep: () => void;
}

const StepTwo = ({ nextStep, prevStep }: StepTwoProps) => {
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [activeMilitary, setActiveMilitary] = useState("no");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setDob(parsedData.dob || "");
        setSsn(parsedData.ssn || "");
        setActiveMilitary(parsedData.activeMilitary || "no");
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      dob: string;
      ssn: string;
      activeMilitary: string;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!dob) {
      newErrors.dob = "Required field";
    }
    if (!ssn || ssn.length < 11) {
      newErrors.ssn = "Required field";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (dob) setErrors((prev) => ({ ...prev, dob: "" }));
    if (ssn && ssn.length > 10) setErrors((prev) => ({ ...prev, ssn: "" }));
    if (dob || ssn) {
      updateSessionStorage({ dob, ssn, activeMilitary });
    }
  }, [dob, ssn, activeMilitary]);

  const handleSsnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 9) value = value.slice(0, 9);

    if (value.length > 5) {
      value = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
    } else if (value.length > 3) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    }

    setSsn(value);
  };

  const handleNext = () => {
    if (validate()) {
      if (
        activeMilitary === "yes_member" ||
        activeMilitary === "yes_dependent"
      ) {
        navigate("/sorry");
      } else {
        nextStep();
      }
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Date of birth
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label=""
              className="date-picker-container"
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              value={dob ? dayjs(dob) : null}
              onChange={(newValue) =>
                setDob(newValue ? newValue.format("MM/DD/YYYY") : "")
              }
              format="MM/DD/YYYY"
              sx={{
                "& .Mui-selected": {
                  color: "#FFFFFF",
                  borderRadius: 2,
                  borderWidth: 1,
                  borderColor: "#e91e63",
                  border: "1px solid",
                  backgroundColor: "#f8bbd0",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: errors.dob ? "#e91e63" : undefined,
                  },
                  "&:hover fieldset": {
                    borderColor: errors.dob ? "#e91e63" : undefined,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: errors.dob ? "#e91e63" : undefined,
                  },
                },
              }}
            />
            {errors.dob && (
              <Typography color="error" variant="caption">
                {errors.dob}
              </Typography>
            )}
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            SSN
          </Typography>
          <TextField
            variant="outlined"
            value={ssn}
            onChange={handleSsnChange}
            fullWidth
            size="small"
            sx={{ height: "38px" }}
            placeholder="XXX-XX-XXXX"
            error={!!errors.ssn}
            helperText={errors.ssn}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                marginBottom: "5px",
                color: "#838588",
              }}
            >
              Military Status
            </Typography>
            <RadioGroup
              value={activeMilitary}
              onChange={(e) => setActiveMilitary(e.target.value)}
              sx={{ display: "flex", alignItems: "start" }}
            >
              <FormControlLabel
                value="yes_member"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
                control={
                  <Radio
                    sx={{
                      color: "#00db8f",
                      padding: "0px 9px !important",
                      "&.Mui-checked": {
                        color: "#00db8f",
                        borderRadius: "50%",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      color: "#838588",
                      marginBottom: "20px",
                    }}
                  >
                    YES, I am a regular or reserve member of the Army, Navy,
                    Marine Corps, Air Force or Coast Guard, serving on active
                    duty under a call or order that does not specify a period of
                    30 days or fewer.
                  </Typography>
                }
              />
              <FormControlLabel
                value="yes_dependent"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
                control={
                  <Radio
                    sx={{
                      color: "#00db8f",
                      padding: "0px 9px !important",
                      "&.Mui-checked": {
                        color: "#00db8f",
                        borderRadius: "50%",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      color: "#838588",
                      marginBottom: "20px",
                    }}
                  >
                    YES, I am a dependent of a member of the Armed Forces on
                    active duty as described above, because I am the member's
                    spouse, the member's child under the age of eighteen years
                    old, or I am an individual for whom the member provided more
                    than one-half of my financial support for 180 days
                    immediately preceding today's date.
                  </Typography>
                }
              />
              <FormControlLabel
                value="no"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
                control={
                  <Radio
                    sx={{
                      color: "#00db8f",
                      padding: "0px 9px !important",
                      "&.Mui-checked": {
                        color: "#00db8f",
                        borderRadius: "50%",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", color: "#838588" }}
                  >
                    NO, I am not a regular or reserve member of the Army, Navy,
                    Marine Corps, Air Force or Coast Guard, serving on active
                    duty under a call or order that does not specify a period of
                    30 days or fewer (or a dependent of such a member).
                  </Typography>
                }
              />
            </RadioGroup>
            {errors.selectedStatus && (
              <Typography color="error" variant="caption">
                {errors.selectedStatus}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
    </Container>
  );
};

export default StepTwo;
