import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ThemeConfig from "./theme";
import CssBaseline from '@mui/material/CssBaseline';
import AppWrapper from "./AppWrapper";
import { store } from "./redux/store";
import ErrorPage from "./components/errorBoundary/ErrorPage";
import globalRouter from "./core/globalRouter";

const CssThemeProvider = () => {
  return (
    <ThemeConfig>
      <>
        <CssBaseline />
        <AppWrapper />
      </>
    </ThemeConfig>
  );
}

const App = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <Provider store={store}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <CssThemeProvider />
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
