import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Link,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import AmountInputField from "../../components/common/AmountField";
import AddressForm from "../../components/addressForm/AddressForm";
import { LeadsRequest } from "../../types/genericType";
import { createLead } from "../../services/leadsService";
import ConsentModal from "../Modals/ConsentModal";

interface StepOneProps {
  nextStep: () => void;
}

const StepOne = ({ nextStep }: StepOneProps) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    firstName: "",
    lastName: "",
    email: "",
    primaryAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
      timeAtAddress: "",
      phoneNumber: "",
    },
    checked: true,
  });

  const [errors, setErrors] = useState({
    amount: "",
    firstName: "",
    lastName: "",
    email: "",
    primaryAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
      timeAtAddress: "",
      phoneNumber: "",
    },
    checked: "",
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        const stepOneData = {
          amount: parsedData.amount || "",
          firstName: parsedData.firstName || "",
          lastName: parsedData.lastName || "",
          email: parsedData.email || "",
          primaryAddress: {
            addressLine1: parsedData.primaryAddress?.addressLine1 || "",
            zip: parsedData.primaryAddress?.zip || "",
            city: parsedData.primaryAddress?.city || "",
            state: parsedData.primaryAddress?.state || "",
            timeAtAddress: parsedData.primaryAddress?.timeAtAddress || "",
            phoneNumber: parsedData.primaryAddress?.phoneNumber || "",
          },
          checked: parsedData.checked || true,
        };
        setFormData((prevData) => ({
          ...prevData,
          ...stepOneData,
        }));
        console.log("Retrieved formData from sessionStorage:", parsedData);
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidPhone = (phone: string) => {
    const re = /^\d{3}-\d{3}-\d{4}$/;
    return re.test(phone);
  };

  const validate = useCallback(() => {
    const newErrors: any = {};
    const fields = {
      ...formData,
      primaryAddress: {
        ...formData.primaryAddress,
      },
    };

    Object.keys(fields).forEach((field) => {
      if (field !== "primaryAddress") {
        if (!fields[field as keyof typeof fields]) {
          newErrors[field] = "Required field";
        } else {
          if (field === "email" && !isValidEmail(formData.email)) {
            newErrors[field] = "Invalid email";
          }
          if (
            field === "phoneNumber" &&
            !isValidPhone(formData.primaryAddress.phoneNumber)
          ) {
            newErrors.primaryAddress.phoneNumber = "Invalid phone number";
          }
        }
      } else {
        Object.keys(fields.primaryAddress).forEach((addressField) => {
          if (
            !fields.primaryAddress[
              addressField as keyof typeof fields.primaryAddress
            ]
          ) {
            newErrors.primaryAddress = newErrors.primaryAddress || {};
            newErrors.primaryAddress[addressField] = "Required field";
          }
        });
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleNext = () => {
    sessionStorage.setItem("Lead", JSON.stringify(formData));

    const { addressLine1, zip, city, state, timeAtAddress, phoneNumber } =
      formData.primaryAddress;
    const leadBody: LeadsRequest = {
      source: "EasyLoansDirect",
      firstName: formData.firstName,
      lastName: formData.lastName,
      address: {
        addressLine1,
        zip,
        city,
        state,
        timeAtAddress,
      },
      email: formData.email,
      loanAmount: formData.amount,
      phoneNumber: phoneNumber,
    };
    if (validate()) {
      createLead(leadBody);
      nextStep();
    }
  };

  const handleChange = (
    field: keyof typeof formData,
    value: string | boolean
  ) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    if (field === "checked") {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value as boolean,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value as string,
      }));
    }
    sessionStorage.setItem("LoanApplication", JSON.stringify(formData));
  };

  const handleAddressChange = (change: { id: string; value: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      primaryAddress: {
        ...prevData.primaryAddress,
        [change.id]: change.value,
      },
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      primaryAddress: {
        ...prevErrors.primaryAddress,
        [change.id]: "",
      },
    }));

    sessionStorage.setItem(
      "LoanApplication",
      JSON.stringify({
        ...formData,
        primaryAddress: {
          ...formData.primaryAddress,
          [change.id]: change.value,
        },
      })
    );
  };

  const handleClose = () => setOpen(false);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Requested Loan Amount
          </Typography>
          <AmountInputField
            label=""
            amount={formData.amount}
            setAmount={(value: string | boolean) =>
              handleChange("amount", value)
            }
            required
            error={!!errors.amount}
            helperText={errors.amount}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            First Name
          </Typography>
          <TextField
            variant="outlined"
            value={formData.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter First"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Last Name
          </Typography>
          <TextField
            variant="outlined"
            value={formData.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Last"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Email
          </Typography>
          <TextField
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            fullWidth
            size="small"
            placeholder="email@example.com"
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <AddressForm
          value={formData.primaryAddress}
          onChange={handleAddressChange}
          required
          detailAddress
          errors={errors.primaryAddress}
          addressRequired
        />
        <Grid item xs={12}>
          <FormControlLabel
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            control={
              <Checkbox
                checked={formData.checked}
                onChange={(e) => handleChange("checked", e.target.checked)}
                name="checked"
                color="primary"
                sx={{
                  padding: "0px 9px",
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "#838588",
                }}
              >
                I consent to the electronic communications agreement{" "}
                <Link
                  href="#"
                  underline="always"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  here
                </Link>
                .
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <ConsentModal open={open} handleClose={handleClose} />
    </Container>
  );
};

export default StepOne;
