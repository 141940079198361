import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

interface SorryExistingUserPageProps {
  checkedProperty?: string;
}

const SorryExistingUserPage: React.FC<SorryExistingUserPageProps> = ({
  checkedProperty,
}) => {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center" }}>
      <Typography variant="h5" component="h5" fontWeight="bold" color="#00db8f">
        Hey, it looks like you've applied before!
      </Typography>
      <Typography
        sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
      >
        We recognized you by some of the information you've entered.
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <Typography
          sx={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
        >
          The good news is that you're even closer to getting approved for your
          loan!
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Login to your personal Lift Credit portal" />
            <Link
              href="/Login"
              sx={{ textDecoration: "none", color: "primary.main" }}
            >
              here
            </Link>
            <ListItemText primary="to apply for a new loan." />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Don't think this is right? Go back and make sure you've
                  entered the correct <strong>{checkedProperty}</strong>.
                </>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default SorryExistingUserPage;
