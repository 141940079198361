import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom"; // Import useNavigate

type MenuCompProps = {
  userDetails: any;
};

const MenuComponent = (props: MenuCompProps) => {
  const { userDetails } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate(); 

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  return (
    <Container sx={{ paddingTop: "100px", backgroundColor: "#f4f4f4" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", color: "#5a5a5b" }}
          >
            Welcome Back,
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "14px", color: "#5a5a5b" }}
          >
            {userDetails?.user?.name}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/portal")}
          >
            Home
          </Button>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/rewards")} 
          >
            Redeem
          </Button>

          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            id="account-button"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Account
            <ExpandMoreOutlined />
          </Button>

          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "account-button",
            }}
          >
            <MenuItem
              onClick={() => handleNavigation("/profile")}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              onClick={() => handleNavigation("/loan-history")}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              Loan History
            </MenuItem>
          </Menu>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#004d40",
              color: "#fff",
              fontSize: "14px",
              textTransform: "capitalize",
              ml: 2,
            }}
            onClick={() => navigate("/")}
          >
            Request New Loan <ChevronRightIcon />
          </Button>
        </Box>
      </Toolbar>
    </Container>
  );
};

export default MenuComponent;
