import React, { useEffect, useState } from "react";
import { getDisclaimers, getRewards } from "../../services/rewardsService";
import { SelectChangeEvent } from "@mui/material";
import "./RedeemPage.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography, Button, MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RewardsResponse } from "../../types/genericType";

const RedeemPage = () => {
  const [rewardsData, setRewardsData] = useState<RewardsResponse>({
    count: 0,
    sort: [],
    pages: 0,
    page: 0,
    perPage: 0,
    resources: [],
  });
  const [disclaimers, setDisclaimers] = useState<string[]>([]);
  const [selectedReward, setSelectedReward] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const perPage = "500";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRewardsAndDisclaimers = async () => {
      try {
        const rewards = await getRewards({ perPage });
        setRewardsData(rewards);
        localStorage.setItem("rewards", JSON.stringify(rewards?.resources));
        const disclaimers = await getDisclaimers({ perPage });
        setDisclaimers(disclaimers.resources);
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      }
    };
    fetchRewardsAndDisclaimers();
  }, []);

  const handleImageClick = (index: number) => {
    setSelectedOption("");
    setSelectedReward(index);
  };

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleAddToCart = (rewardSku: string | null) => {
    if (!rewardSku) {
      alert("Please select a valid option.");
      return;
    }

    const cartItems = JSON.parse(localStorage.getItem("rewardCart") || "[]");
    localStorage.setItem(
      "rewardCart",
      JSON.stringify([...cartItems, rewardSku])
    );
    navigate("/rewards/cart");
  };

  const rewardsDetails = sessionStorage.getItem("rewardsDetails")
    ? JSON.parse(sessionStorage.getItem("rewardsDetails") as string)
    : null;

  return (
    <div className="redeem-page">
      <div className="section-head">
        <div>
          <Typography
            sx={{
              color: "#838588",
              fontSize: "12px",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            REWARDS
          </Typography>
          <Typography
            sx={{ color: "#00db8f", fontSize: "16px", fontWeight: "500" }}
          >
            Redeem Points
          </Typography>
        </div>
        <div className="rewards-point-summary">
          <span
            className="point-summary-value"
            style={{ color: "#00db8f", fontSize: "30px", fontWeight: "500" }}
          >
            {rewardsDetails?.pointAmount ?? 0}
          </span>{" "}
          <span
            style={{ color: "#838588", fontSize: "16px", fontWeight: "500" }}
          >
            Total Rewards Points
          </span>
        </div>
      </div>

      <div className="star" />
      <h1 className="main-title">
        Earn rewards for every on-time payment you make
      </h1>
      <h3 className="sub-title">Lift Credit rewards you 1% cashback</h3>
      <div className="grid">
        <div className="grid-container">
          {rewardsData.resources.length > 0 ? (
            rewardsData.resources.map((reward, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                {selectedReward === index ? (
                  <div className="reward-image selected-card">
                    <h6 className="brand">
                      {reward.brandName.toUpperCase()} GIFT CARD
                    </h6>
                    <Select
                      value={selectedOption}
                      onChange={handleOptionChange}
                      displayEmpty
                      sx={{
                        width: "100%",
                        marginBottom: "10px",
                        height: "40px",
                      }}
                    >
                      <MenuItem value="">
                        <em>Select an Option</em>
                      </MenuItem>
                      {reward.options?.map((r) => (
                        <MenuItem value={r.sku} key={r.sku}>
                          {r.points} PTS - ${r.amount}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#004d40",
                        color: "#fff",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        ml: 2,
                        ":hover": {
                          backgroundColor: "#004d40",
                        },
                      }}
                      onClick={() => handleAddToCart(selectedOption)}
                    >
                      Add to Order <ChevronRightIcon />
                    </Button>
                  </div>
                ) : (
                  <img
                    src={reward.imageUrl}
                    alt={reward.rewardName}
                    className="reward-image"
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            ))
          ) : (
            <Typography>No rewards available at the moment.</Typography>
          )}
        </div>
      </div>
      <div className="rewards-disclaimer">
        <div className="disclaimers">
          {disclaimers.map((disclaimer, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: disclaimer }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RedeemPage;
