import React, { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Link,
} from "@mui/material";
import Dropdown from "../../components/common/Dropdown";
import IconButton from "../../components/IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import Recaptcha from "../../components/recaptcha/Recaptcha";
import TermsOfUseModal from "../Modals/TermsOfUseModal";
import PrivacyPolicyModal from "../Modals/PrivacyPolicyModal";
import ConsentEmailSMSModal from "../Modals/ConsentEmailSMSModal";
import {
  LoanApplication,
  submitLoanApplication,
} from "../../services/leadsService";

interface StepFiveProps {
  prevStep: () => void;
}

const StepFive = ({ prevStep }: StepFiveProps) => {
  const [promoCode, setPromoCode] = useState("");
  const [sourceMethod, setSourceMethod] = useState("Radio");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [termsOpen, setTermsOpen] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);
  const [emailConsentOpen, setEmailConsentOpen] = useState(false);
  const [loanApplicationData, setLoanApplicationData] =
    useState<LoanApplication>({
      verification: "",
      firstName: "",
      lastName: "",
      address: {
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
        timeAtAddress: "",
      },
      email: "",
      monthlyIncome: "",
      dateOfBirth: "",
      phoneNumber: "",
      consentToMarketing: false,
      bankVerificationRequestResult: "",
      socialSecurityNumber: "",
      advertisingMethod: "",
      advertisingMethodText: null,
      licenseNumber: "",
      licenseState: "",
      timeAtAddress: "",
      optInToMarketingMessage: false,
      optInToReceiveTextMessage: false,
      incomeSources: [],
      bankAccounts: [],
      bankCard: {
        nameOnCard: "",
        cardNumber: "",
        expiration: "",
        billingAddressSameAsPrimary: false,
        billingAddress: {
          addressLine1: "",
          city: "",
          state: "",
          zip: "",
          timeAtAddress: "",
        },
      },
    });

  const terms = [
    "I expressly authorize Lift Credit and its affiliates to share among them any transaction history related to my financial products or services received through or serviced by Lift Credit for the purpose of evaluating me for credit.",
    "I hereby give permission to verify the information provided in this application for a credit decision, which may include contacting employers, personal references, and obtaining consumer reports at any time for any purpose.",
    "If your loan application doesn’t meet our minimum requirements, Lift Credit may forward your information to a partner who can help find a lender that may better fit your situation.",
  ];

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (captchaToken) {
      setErrors({
        ...errors,
        captcha: "",
      });
      setLoanApplicationData({
        ...loanApplicationData,
        verification: captchaToken,
      });
    }
  }, [captchaToken, errors, loanApplicationData]);

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setEmail(parsedData.email);
        setSourceMethod(parsedData.sourceMethod);
        setLoanApplicationData(parsedData);
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      sourceMethod: string;
      promoCode: string;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };

  useEffect(() => {
    if (sourceMethod || promoCode) {
      updateSessionStorage({ sourceMethod, promoCode });
    }
  }, [sourceMethod, promoCode]);

  const handleApply = () => {
    if (!captchaToken) {
      setErrors({
        ...errors,
        captcha: "Please verify you are not a robot.",
      });
    }
    if (validate()) {
      submitLoanForm();
    }
  };

  const submitLoanForm = async () => {
    await submitLoanApplication(loanApplicationData);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            id="sourceMethod"
            label="How did you hear about us?"
            value={sourceMethod}
            options={[{ value: "Radio", label: "Radio" }]}
            onChange={setSourceMethod}
            required={true}
            error={errors.sourceMethod}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Promo Code (Optional)
          </Typography>
          <TextField
            variant="outlined"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Promo Code (Optional)"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
              fontWeight: "400",
            }}
          >
            An account with the username: <b>{email}</b> will be created for
            your personal loan portal where you will be able to check the status
            of your loan, redeem rewards, and make loan payments once your loan
            is approved and active.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Verification
          </Typography>
          <Recaptcha setCaptchaToken={setCaptchaToken} />
          {errors.captcha && (
            <Typography color="error" variant="caption">
              {errors.captcha}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              marginTop: "20px",
              color: "#838588",
            }}
          >
            By clicking "Apply Now" you agree to the following:
          </Typography>
          <ul>
            <li>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  paddingLeft: "8px",
                  // marginBottom: "5px",
                  color: "#838588",
                }}
              >
                I consent to Lift Credit's{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => setTermsOpen(true)}
                  >
                    Terms of Use
                  </Link>
                }{" "}
                and{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => setPolicyOpen(true)}
                  >
                    Privacy Policy
                  </Link>
                }
                .
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  paddingLeft: "8px",
                  // marginBottom: "5px",
                  color: "#838588",
                }}
              >
                I consent to receive calls (including SMS text messages) from
                Lift Credit regarding any account as specified{" "}
                {
                  <Link
                    href="#"
                    underline="always"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => setEmailConsentOpen(true)}
                  >
                    here
                  </Link>
                }
                .
              </Typography>
            </li>
            {terms.map((term, index) => (
              <li key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    paddingLeft: "8px",
                    color: "#838588",
                  }}
                >
                  {term}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleApply}
          title="APPLY NOW"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <TermsOfUseModal
        open={termsOpen}
        handleClose={() => setTermsOpen(false)}
      />
      <PrivacyPolicyModal
        open={policyOpen}
        handleClose={() => setPolicyOpen(false)}
      />
      <ConsentEmailSMSModal
        open={emailConsentOpen}
        handleClose={() => setEmailConsentOpen(false)}
      />
    </Container>
  );
};

export default StepFive;
