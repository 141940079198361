import React from 'react';
import { Box, Typography } from '@mui/material';

interface SorryDenialLendingPartnerPageProps {
  firstName: string;
}

const SorryDenialLendingPartnerPage: React.FC<SorryDenialLendingPartnerPageProps> = ({ firstName }) => {
  return (
    <Box sx={{ padding: '2rem', textAlign: 'center' }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
        We're Sorry!
      </Typography>

      <Box sx={{ marginTop: '2rem' }}>
        <Typography variant="h5">
          Sorry, {firstName}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '1rem' }}>
          We were unable to connect you with a lender in our partner network.
        </Typography>
        <Typography variant="h5" sx={{ marginTop: '2rem' }}>
          Thanks for applying!
        </Typography>
      </Box>
    </Box>
  );
};

export default SorryDenialLendingPartnerPage;
