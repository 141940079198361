import { PaletteColorOptions, PaletteOptions } from "@mui/material";

export const primary: PaletteColorOptions = {
  main: "#606ADD",
  light: "#8189E4",
  dark: "#2D3AD2",
};

export const error: PaletteColorOptions = {
  main: "#D32F2F",
  light: "#EF5350",
  dark: "#C62828",
};

export const palette: PaletteOptions = {
  primary: { ...primary },
  error: { ...error },
};
