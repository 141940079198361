import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { BankDataType } from "../types/genericType";

export const getBankInfo = async (routingNumber: String): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get(
      `${apiEndPoints.bankName}/${routingNumber}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const validateBankInfo = async (
  bankData: BankDataType
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.bankInfo}`,
      bankData
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
