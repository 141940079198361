const Constants = {
  recaptchaSiteKey: "6LcWtywqAAAAAOdB54E8xDp2MGIdp2U5VSv7PfUb",
  infoLiftCredit: {
    companyName: "Lift Credit",
    websiteUrl: "https://www.liftcredit.com",
    phone: "801-477-1222",
    email: "support@liftcredit.com",
    contactInfo: [
      { name: "Utah Customer Service", value: "801-477-1222" },
      { name: "Idaho Customer Service", value: "208-629-0065" },
      { name: "Fax", value: "801-477-1224" },
      { name: "Email", value: "support@liftcredit.com" },
    ],
  },
  infoLiftDental: {
    companyName: "Lift Dental",
    websiteUrl: "https://www.liftdental.com",
    phone: "305-209-1882",
    email: "support@liftdental.com",
    contactInfo: [
      { name: "Customer Service", value: "385-209-1882" },
      { name: "Fax", value: "801-477-1224" },
      { name: "Email", value: "support@liftdental.com" },
    ],
  },
  emptyOptions: [],
  stateOptions: [
    { name: "Alabama", value: "AL" },
    { name: "Alaska", value: "AK" },
    { name: "Arizona", value: "AZ" },
    { name: "Arkansas", value: "AR" },
    { name: "California", value: "CA" },
    { name: "Colorado", value: "CO" },
    { name: "Connecticut", value: "CT" },
    { name: "Delaware", value: "DE" },
    { name: "Florida", value: "FL" },
    { name: "Georgia", value: "GA" },
    { name: "Hawaii", value: "HI" },
    { name: "Idaho", value: "ID" },
    { name: "Illinois", value: "IL" },
    { name: "Indiana", value: "IN" },
    { name: "Iowa", value: "IA" },
    { name: "Kansas", value: "KS" },
    { name: "Kentucky", value: "KY" },
    { name: "Louisiana", value: "LA" },
    { name: "Maine", value: "ME" },
    { name: "Maryland", value: "MD" },
    { name: "Massachusetts", value: "MA" },
    { name: "Michigan", value: "MI" },
    { name: "Minnesota", value: "MN" },
    { name: "Mississippi", value: "MS" },
    { name: "Missouri", value: "MO" },
    { name: "Montana", value: "MT" },
    { name: "Nebraska", value: "NE" },
    { name: "Nevada", value: "NV" },
    { name: "New Hampshire", value: "NH" },
    { name: "New Jersey", value: "NJ" },
    { name: "New Mexico", value: "NM" },
    { name: "New York", value: "NY" },
    { name: "North Carolina", value: "NC" },
    { name: "North Dakota", value: "ND" },
    { name: "Ohio", value: "OH" },
    { name: "Oklahoma", value: "OK" },
    { name: "Oregon", value: "OR" },
    { name: "Pennsylvania", value: "PA" },
    { name: "Rhode Island", value: "RI" },
    { name: "South Carolina", value: "SC" },
    { name: "South Dakota", value: "SD" },
    { name: "Tennessee", value: "TN" },
    { name: "Texas", value: "TX" },
    { name: "Utah", value: "UT" },
    { name: "Vermont", value: "VT" },
    { name: "Virginia", value: "VA" },
    { name: "Washington", value: "WA" },
    { name: "West Virginia", value: "WV" },
    { name: "Wisconsin", value: "WI" },
    { name: "Wyoming", value: "WY" },
  ],
  monthOptions: [
    { name: "Jan", value: "1" },
    { name: "Feb", value: "2" },
    { name: "Mar", value: "3" },
    { name: "Apr", value: "4" },
    { name: "May", value: "5" },
    { name: "Jun", value: "6" },
    { name: "Jul", value: "7" },
    { name: "Aug", value: "8" },
    { name: "Sep", value: "9" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
  ],
  yearOptions: (() => {
    const options = [];
    const year = new Date().getFullYear();

    for (let i = year - 18; i > year - 100; i--) {
      options.push({ name: i.toString(), value: i.toString() });
    }
    return options;
  })(),
  dayOfWeekOptions: [
    { name: "Sunday", value: "sunday" },
    { name: "Monday", value: "monday" },
    { name: "Tuesday", value: "tuesday" },
    { name: "Wednesday", value: "wednesday" },
    { name: "Thursday", value: "thursday" },
    { name: "Friday", value: "friday" },
    { name: "Saturday", value: "saturday" },
  ],
  timeRangeOptions: [
    { name: "0 to 2 months", value: "1" },
    { name: "3 to 6 months", value: "3" },
    { name: "7 to 11 months", value: "7" },
    { name: "12+ months", value: "12" },
  ],
  incomeTypeOptions: [
    { name: "Employment", value: "employment" },
    // { name: "Social Security", value: "socialSecurity" },
    // { name: "Disability", value: "disability" },
    { name: "Retirement", value: "retirement" },
    { name: "Self-Employed", value: "selfEmployed" },
    { name: "Other", value: "other" },
  ],
  payrollTypeOptions: [
    { name: "Cash", value: "cash" },
    { name: "Check", value: "check" },
    { name: "Direct Deposit", value: "directDeposit" },
  ],
  payFrequencyOptions: [
    { name: "Weekly", value: "weekly" },
    { name: "Every Two Weeks", value: "biWeekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Twice per Month", value: "twiceMonthly" },
  ],
  dayOfMonthOptions: (() => {
    const options = [];
    options.push({ name: "1st", value: "1" });
    options.push({ name: "15th", value: "15" });
    options.push({ name: "Last", value: "-1" });

    for (let i = 1; i <= 31; i++) {
      let suffix = "th";
      if (i === 1 || i === 21 || i === 31) suffix = "st";
      else if (i === 2 || i === 22) suffix = "nd";
      else if (i === 3 || i === 23) suffix = "rd";
      options.push({ name: `${i}${suffix}`, value: i.toString() });
    }
    return options;
  })(),
  weekOfMonthOptions: [
    { name: "First", value: "first" },
    { name: "Second", value: "second" },
    { name: "Third", value: "third" },
    { name: "Fourth", value: "fourth" },
    { name: "Last", value: "last" },
  ],
  militaryOptions: [
    { name: "Yes, Member", value: "yes_member" },
    { name: "Yes, Dependent", value: "yes_dependent" },
    { name: "No", value: "no" },
  ],
  bankAccountTypeOptions: [
    { name: "Checking", value: "Checking" },
    { name: "Savings", value: "Savings" },
  ],
  bankAccountStatusOptions: [
    { name: "Active", value: "Active" },
    { name: "Inactive", value: "Inactive" },
  ],
  yesNoOptions: [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ],
  repaymentMethodOptions: [
    { name: "Electronic Fund Transfer (ACH)", value: "Ach" },
    { name: "Debit or Credit Card", value: "DebitCard" },
  ],
  additionalPaymentOptions: [
    { name: "Bank Account", value: "Ach" },
    { name: "Debit or Credit Card", value: "DebitCard" },
  ],
  verifyBankOptions: [
    { name: "Verify Bank Opt In", value: "Yes" },
    { name: "Verify Bank Opt Out", value: "No" },
  ],
  perPageOptions: [
    { name: "5 per page", value: 5 },
    { name: "10 per page", value: 10 },
    { name: "25 per page", value: 25 },
    { name: "50 per page", value: 50 },
  ],
  minimumTimeAtEmployerRequirements: [
    { name: "None", value: 0 },
    { name: "More than 2 months", value: 2 },
    { name: "More than 6 months", value: 6 },
    { name: "More than 11 months", value: 11 },
  ],
};

export default Constants;
