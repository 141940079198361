import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ClearIcon from "@mui/icons-material/Clear";
import Dropdown from "../../components/common/Dropdown";
import Constants from "../../constants/constant";
import { BankDataType } from "../../types/genericType";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconBtn from "../../components/IconBtn/IconBtn";
import SampleCheckModal from "../Modals/SampleCheckModal";
import { getBankInfo, validateBankInfo } from "../../services/bankService";

interface StepFourProps {
  prevStep: () => void;
  nextStep: () => void;
}

const StepFour = ({ prevStep, nextStep }: StepFourProps) => {
  const [open, setOpen] = useState(false);
  const initialBankAccount: BankDataType = {
    routingNumber: "",
    accountNumber: "",
    bankName: "",
    accountType: "",
  };

  const [formData, setFormData] = useState({
    bankAccounts: [initialBankAccount],
    cardHolderName: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
    billingAddressSame: true,
    hasDebitCard: true,
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setFormData((prevData) => ({
          ...parsedData,
          bankAccounts: parsedData.bankAccounts || prevData.bankAccounts,
          cardHolderName: parsedData.cardHolderName || prevData.cardHolderName,
          cardNumber: parsedData.cardNumber || prevData.cardNumber,
          expirationDate: parsedData.expirationDate || prevData.expirationDate,
          securityCode: parsedData.securityCode || prevData.securityCode,
          hasDebitCard: true,
          billingAddressSame: true,
        }));
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      bankAccounts: BankDataType[];
      cardHolderName: string;
      cardNumber: string;
      expirationDate: string;
      securityCode: string;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };

  const [errors, setErrors] = useState({
    bankAccounts: [
      {
        routingNumber: "",
        accountNumber: "",
        bankName: "",
        accountType: "",
      },
    ],
    cardHolderName: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
  });

  useEffect(() => {
    updateSessionStorage({
      bankAccounts: formData.bankAccounts,
      cardHolderName: formData.cardHolderName,
      cardNumber: formData.cardNumber,
      expirationDate: formData.expirationDate,
      securityCode: formData.securityCode,
    });
  }, [formData]);

  const validate = useCallback(() => {
    const newErrors: any = {};

    formData.bankAccounts.forEach((account, index) => {
      const accountErrors: any = {};
      if (!account.routingNumber) {
        accountErrors.routingNumber = "Required field";
      }
      if (!account.accountNumber) {
        accountErrors.accountNumber = "Required field";
      }
      if (!account.bankName) {
        accountErrors.bankName = "Required field";
      }
      if (!account.accountType) {
        accountErrors.accountType = "Required field";
      }
      if (Object.keys(accountErrors).length > 0) {
        newErrors.bankAccounts = newErrors.bankAccounts || [];
        newErrors.bankAccounts[index] = accountErrors;
      }
    });

    if (formData.hasDebitCard) {
      if (!formData.cardHolderName) {
        newErrors.cardHolderName = "Required field";
      }
      if (!formData.cardNumber) {
        newErrors.cardNumber = "Required field";
      } else if (!/^\d{16}$/.test(formData.cardNumber.replace(/\s+/g, ""))) {
        newErrors.cardNumber = "Invalid card number";
      }
      if (!formData.expirationDate) {
        newErrors.expirationDate = "Required field";
      } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expirationDate)) {
        newErrors.expirationDate = "Invalid expiration date";
      }
      if (!formData.securityCode) {
        newErrors.securityCode = "Required field";
      } else if (!/^\d{3,4}$/.test(formData.securityCode)) {
        newErrors.securityCode = "Invalid CVV";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleNext = async () => {
    if (validate()) {
      const validBankInfo = await validateBankInfo({
        routingNumber: formData.bankAccounts[0].routingNumber,
        accountNumber: formData.bankAccounts[0].accountNumber,
        bankName: formData.bankAccounts[0].bankName,
      });
      if (validBankInfo?.allow) {
        nextStep();
      }
    }
  };

  const handleBankAccountChange = async (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedBankAccounts = formData.bankAccounts.map((account, i) =>
      i === index ? { ...account, [field]: value } : account
    );

    setFormData({ ...formData, bankAccounts: updatedBankAccounts });

    if (field === "routingNumber") {
      const bankName = await getBankInfo(value);
      updatedBankAccounts[index].bankName = bankName;
      setFormData({ ...formData, bankAccounts: updatedBankAccounts });
    }

    const updatedErrors = errors.bankAccounts.map((error, i) =>
      i === index ? { ...error, [field]: "" } : error
    );
    setErrors({ ...errors, bankAccounts: updatedErrors });
  };

  const handleCardChange = (field: string, value: string) => {
    let formattedValue = value;

    if (field === "cardNumber") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{4})(?=\d)/g, "$1-")
        .substring(0, 19);
    } else if (field === "expirationDate") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{2})(?=\d)/g, "$1/")
        .substring(0, 5);
    } else if (field === "securityCode") {
      formattedValue = value.replace(/\D/g, "").substring(0, 4);
    }

    setFormData({ ...formData, [field]: formattedValue });
    setErrors({ ...errors, [field]: "" });
  };

  const handleAddBankAccount = () => {
    setFormData({
      ...formData,
      bankAccounts: [...formData.bankAccounts, initialBankAccount],
    });
    setErrors({
      ...errors,
      bankAccounts: [
        ...errors.bankAccounts,
        {
          routingNumber: "",
          accountNumber: "",
          bankName: "",
          accountType: "",
        },
      ],
    });
  };

  const handleRemoveBankAccount = (index: number) => {
    setFormData({
      ...formData,
      bankAccounts: formData.bankAccounts.filter((_, i) => i !== index),
    });
    setErrors({
      ...errors,
      bankAccounts: errors.bankAccounts.filter((_, i) => i !== index),
    });
  };

  const handleAddDebitCard = () => {
    setFormData({ ...formData, hasDebitCard: true });
  };

  const handleRemoveDebitCard = () => {
    setFormData({
      ...formData,
      cardHolderName: "",
      cardNumber: "",
      expirationDate: "",
      securityCode: "",
      hasDebitCard: false,
    });
    setErrors({
      ...errors,
      cardHolderName: "",
      cardNumber: "",
      expirationDate: "",
      securityCode: "",
    });
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {formData.bankAccounts.map((bankAccount, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    color: "#00db8f",
                    fontWeight: 500,
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00594f",
                    },
                  }}
                  onClick={() => handleRemoveBankAccount(index)}
                >
                  <ClearIcon fontSize="small" />
                  <Typography
                    sx={{
                      fontFamily: "AvenirNext-DemiBold",
                      marginLeft: "4px",
                    }}
                  >
                    Remove Bank Account
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Routing Number
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.routingNumber}
                onChange={(e) =>
                  handleBankAccountChange(
                    index,
                    "routingNumber",
                    e.target.value
                  )
                }
                fullWidth
                size="small"
                placeholder="Enter Routing Number"
                error={!!errors.bankAccounts[index]?.routingNumber}
                helperText={errors.bankAccounts[index]?.routingNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Account Number
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.accountNumber}
                onChange={(e) =>
                  handleBankAccountChange(
                    index,
                    "accountNumber",
                    e.target.value
                  )
                }
                fullWidth
                size="small"
                placeholder="Enter Account Number"
                error={!!errors.bankAccounts[index]?.accountNumber}
                helperText={errors.bankAccounts[index]?.accountNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Bank Name
              </Typography>
              <TextField
                variant="outlined"
                value={bankAccount.bankName}
                onChange={(e) =>
                  handleBankAccountChange(index, "bankName", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Bank Name"
                error={!!errors.bankAccounts[index]?.bankName}
                helperText={errors.bankAccounts[index]?.bankName}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                id="accountType"
                label="Account Type"
                value={bankAccount.accountType || ""}
                options={Constants.bankAccountTypeOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(e) =>
                  handleBankAccountChange(index, "accountType", e)
                }
                required={true}
                error={errors.bankAccounts[index]?.accountType}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
            }}
          >
            We require your bank information because we typically send funds
            through ACH deposit, and ACH may also be used as a form of payment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            sx={{
              color: "#00db8f",
              fontWeight: 500,
              cursor: "pointer",
              "&:hover": {
                color: "#00594f",
              },
            }}
            onClick={handleAddBankAccount}
          >
            <Typography
              sx={{
                fontFamily: "AvenirNext-DemiBold",
                marginLeft: "4px",
              }}
            >
              + Add Another Bank Account
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
            }}
          >
            Adding a Debit Card is optional, but may also be used as a form of
            payment.
          </Typography>
        </Grid>
        {formData.hasDebitCard ? (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Name on Card
              </Typography>
              <TextField
                variant="outlined"
                value={formData.cardHolderName}
                onChange={(e) =>
                  handleCardChange("cardHolderName", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Card Holder Name"
                error={!!errors.cardHolderName}
                helperText={errors.cardHolderName}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Card Number
              </Typography>
              <TextField
                variant="outlined"
                value={formData.cardNumber}
                onChange={(e) => handleCardChange("cardNumber", e.target.value)}
                fullWidth
                size="small"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Expiration
              </Typography>
              <TextField
                variant="outlined"
                value={formData.expirationDate}
                onChange={(e) =>
                  handleCardChange("expirationDate", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="MM/YY"
                error={!!errors.expirationDate}
                helperText={errors.expirationDate}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Security Code
              </Typography>
              <TextField
                variant="outlined"
                value={formData.securityCode}
                onChange={(e) =>
                  handleCardChange("securityCode", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="CVV"
                error={!!errors.securityCode}
                helperText={errors.securityCode}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                value={formData?.billingAddressSame}
                onChange={(e) =>
                  handleCardChange("billingAddressSame", e.target.value)
                }
                sx={{ display: "flex", alignItems: "start" }}
              >
                <FormControlLabel
                  value="checked2"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "#838588",
                        marginBottom: "20px",
                      }}
                    >
                      Yes, my billing address is the same as my mailing address.
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="checked3"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#838588" }}
                    >
                      No, my billing address is not the same as my mailing
                      address.
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{
                  color: "#00db8f",
                  fontWeight: 500,
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00594f",
                  },
                }}
                onClick={handleRemoveDebitCard}
              >
                <ClearIcon fontSize="small" />
                <Typography
                  sx={{
                    fontFamily: "AvenirNext-DemiBold",
                    marginLeft: "4px",
                  }}
                >
                  Remove Debit Card
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{
                color: "#00db8f",
                fontWeight: 500,
                cursor: "pointer",
                "&:hover": {
                  color: "#00594f",
                },
              }}
              onClick={handleAddDebitCard}
            >
              <Typography
                sx={{
                  fontFamily: "AvenirNext-DemiBold",
                  marginLeft: "4px",
                }}
              >
                + Add a Debit Card
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <SampleCheckModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
};

export default StepFour;
