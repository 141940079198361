import axios, { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { AddressResponse } from "../types/genericType";

export const getCityStateByZip = async (zip: String): Promise<AddressResponse> => {
    try {
      const response: AxiosResponse<AddressResponse> =
        await axios.get(`${apiEndPoints.address}/${zip}`);
      return response?.data;
    } catch (err) {
      throw err;
    }
  };