import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalProps } from "../../types/genericType";

const HelpIncomeModal = (props: ModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "900px",
          height: "90vh",
          overflowY: "auto",
          margin: "auto",
          padding: 4,
          marginTop: "4%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "400",
            textAlign: "center",
            color: "black",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Terms of Use
        </Typography>
        <Box sx={{ color: "#838588", padding: "30px 50px" }}>
          <p>
            You are using the website of Lift Credit, LLC (a Utah Limited
            Liability Corporation) (referred to in this document and other
            places on the sites as “Lift Credit,” “Lift” “we” or “us”). The site
            address is http://www.LiftCredit.com (the “site” or “website”). Lift
            Credit, LLC is the sole owner of this site. Set forth below are the
            terms and conditions set out for the use of this website. You agree
            to be bound by the conditions and terms set forth herein if you use
            the site. This document, other important site documents, and site
            pages update periodically and you agree to be bound by those changes
            as continued use of the site signifies your acceptance of such.
          </p>

          <h4>Intellectual Property</h4>

          <p>
            The LiftCredit.com site and its full content, including but not
            limited to, articles, marketing material, logos, graphics, color
            schemes, design, text, illustrations, software, audio and video
            content, testimonials, blogs, images—all considered intellectual
            property—are protected by United States copyright, trademark, and
            other laws. All such is also protected by international conventions
            and laws of other countries, regardless of whether we conduct
            business in that country. Lift Credit intellectual property is owned
            and/or controlled by Lift Credit, LLC. Material may be contained
            that is provided by another party and Lift Credit controls such
            material with permission from the provider. The entire site is also
            protected by copyright as a collective work and/or compilation.
          </p>

          <p>
            This site, nor any portion thereof, may be duplicated, copied,
            mimicked, or exploited for commercial purposes without Lift Credit,
            LLC granting express consent.
          </p>

          <p>
            The name and site address “LiftCredit.com” and any related slogans,
            logos, mottos, and marks are trademarks and design marks of Lift
            Credit, LLC.
          </p>

          <h4>USE OF Lift Credit WEBSITE</h4>

          <p>
            You promise that you will not use this website for unlawful or
            prohibited purposes including those set out by this Terms of Use
            document. You may not cause damage to or through this website nor
            communicate with or through the site in contradiction or violation
            of local, state, national or international law.
          </p>

          <p>
            You agree to defend, indemnify and hold Lift Credit, LLC, its
            officers, directors, employees, agents, licensors, business
            associates, and suppliers harmless from and against any actual or
            threatened claims, actions or demands, liabilities and settlements
            including, without limitation, reasonable legal and accounting fees,
            resulting from, or alleged to result from, your use of the Site in a
            manner that violates or is alleged to violate this Terms and
            Conditions statement or any applicable law.
          </p>

          <h4>TRANSACTIONS</h4>

          <p>
            The Lift Credit website, www.LiftCredit.com, enables you to submit
            an application for financial services provided by Lift Credit, LLC.
            The terms of such loans and governing of application/participation
            in said loan programs are outlined in the License Agreement and set
            forth by the State of Utah, where Lift Credit, LLC is licensed.
            After receiving a loan application, we reserve the right to
            accept/decline your application as provided in the Loan Agreement
            that governs the loan for which you apply.
          </p>

          <h4>Use of Communication Services</h4>

          <p>
            The website at www.LiftCredit.com may contain bulletin board
            services, chat areas, news groups, forums, communities, personal web
            pages, calendars, and/or other message or communication facilities
            designed to enable you to communicate with the public at large or
            with a group (collectively, “Communication Services”). You agree to
            use the Communication Services only to post, send, and receive
            messages and material that are proper and related to the particular
            Communication Service. By way of example, and not as a limitation,
            you agree that when using a Communication Service, you will not:
            Defame, abuse, harass, stalk, threaten or otherwise violate the
            legal rights (such as rights of privacy and publicity) of others.
            Publish, post, upload, distribute or disseminate any inappropriate,
            profane, defamatory, infringing, obscene, indecent or unlawful
            topic, name, material or information.
          </p>

          <p>
            Upload files that contain software or other material protected by
            intellectual property laws (or by rights of privacy of publicity)
            unless you own or control the rights thereto or have received all
            necessary consents.
          </p>

          <p>
            Upload files that contain viruses, corrupted files, or any other
            similar software or programs that may damage the operation of
            another’s computer.
          </p>

          <p>
            Advertise or offer to sell or buy any goods or services for any
            business purpose, unless such Communication Service specifically
            allows such messages.
          </p>
          <p>
            Conduct or forward surveys, contests, pyramid schemes or chain
            letters.
          </p>
          <p>
            Download any file posted by another user of a Communication Service
            that you know, or reasonably should know, cannot be legally
            distributed in such manner.
          </p>
          <p>
            Falsify or delete any author attributions, legal or other proper
            notices or proprietary designations or labels of the origin or
            source of software or other material contained in a file that is
            uploaded.
          </p>
          <p>
            Restrict or inhibit any other user from using and enjoying the
            Communication Services.
          </p>
          <p>
            Violate any code of conduct or other guidelines that may be
            applicable for any particular Communication Service. Harvest or
            otherwise collect information about others, including e-mail
            addresses, without their consent.
          </p>
          <p>Violate any applicable laws or regulations.</p>
          <p>
            Lift Credit, LLC has no obligation to monitor the Communication
            Services. However, we reserve the right to review materials posted
            to a Communication Service and to remove any materials in its sole
            discretion. Lift Credit, LLC reserves the right to terminate your
            access to any or all of the Communication Services at any time
            without notice for any reason whatsoever.
          </p>
          <p>
            We reserve the right at all times to disclose any information as
            necessary to satisfy any applicable law, regulation, legal process
            or governmental request, or to edit, refuse to post or to remove any
            information or materials, in whole or in part, in our sole
            discretion.
          </p>
          <p>
            You agree to always use caution when giving out any personally
            identifying information about yourself or your children in any
            Communication Service. Lift Credit, LLC does not control or endorse
            the content, messages or information found in any Communication
            Service and, therefore, Lift Credit, LLC specifically disclaims any
            liability with regard to the Communication Services and any actions
            resulting from your participation in any Communication Service.
            Managers and hosts are not authorized Lift Credit, LLC.
            spokespersons, and their views do not necessarily reflect those of
            Lift Credit, LLC.
          </p>
          <p>
            Materials uploaded to a Communication Service may be subject to
            posted limitations on usage, reproduction and/or dissemination. You
            are responsible for adhering to such limitations if you download the
            materials.
          </p>
          <h4>Termination/access restriction</h4>
          <p>
            Lift Credit, LLC reserves the right, in its sole discretion, to
            terminate your access to the LiftCredit.com website and the related
            services or any portion thereof at any time, without notice.
            Disclaimer of Warranty, Limitation of Liability, and Release Lift
            Credit, LLC does not guarantee the accuracy of information found on
            the LiftCredit.com Site. Your reliance on information found on the
            Site is at your own risk.
          </p>
          <p>
            THE SITE, AND ALL ITS CONTENTS, IS PROVIDED TO YOU “AS IS.” Lift
            Credit, LLC MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES, DATA ACCURACY, OR
            QUIET ENJOYMENT.
          </p>
          <p>
            UNDER NO CIRCUMSTANCES WILL Lift Credit, LLC OR ANY OTHERS INVOLVED
            IN CREATING THE SITE AND ITS CONTENTS BE LIABLE FOR ANY DAMAGES OR
            INJURY, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES RESULTING FROM ANY
            CIRCUMSTANCE INVOLVING THE SITE OR ITS CONTENT (INCLUDING, BUT NOT
            LIMITED TO, DAMAGES OR INJURY CAUSED BY ERROR, OMISSION,
            INTERRUPTION, DEFECT, FAILURE OF PERFORMANCE, MISDIRECTED OR
            REDIRECTED TRANSMISSIONS, FAILED INTERNET CONNECTIONS, UNAUTHORIZED
            USE OF THIS SITE, LOST DATA, DELAY IN OPERATION OR TRANSMISSION,
            BREACH OF SECURITY, LINE FAILURE, DEFAMATORY, OFFENSIVE OR ILLEGAL
            CONDUCT OF ANY USER OF THIS SITE, OR COMPUTER VIRUS, WORM, TROJAN
            HORSE OR OTHER HARMFUL COMPONENT), EVEN IF YOU HAVE ADVISED Lift
            Credit, LLC IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGE.
          </p>
          <p>
            Lift Credit, LLC DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
            REGARDING THE MATERIALS ON THIS SITE IN TERMS OF THEIR TIMELINESS,
            CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. WHILE WE MAKE
            REASONABLE EFFORTS TO PROVIDE ACCURATE AND TIMELY INFORMATION ABOUT
            Lift Credit, LLC ON THIS SITE, YOU SHOULD NOT ASSUME THAT THE
            INFORMATION PROVIDED IS ALWAYS UP TO DATE OR THAT THIS SITE CONTAINS
            ALL THE RELEVANT INFORMATION AVAILABLE ABOUT OUR COMPANY. WE
            UNDERTAKE NO OBLIGATION TO VERIFY OR MAINTAIN THE CURRENCY OF SUCH
            INFORMATION.
          </p>
          <p>
            Applicable law may not allow the exclusion of certain warranties or
            the limitation or exclusion of liability for incidental or
            consequential damages. Accordingly, some of the above limitations or
            exclusions may not apply to you. However, in no event shall Lift
            Credit, LLC’s aggregate liability to you or any third party for
            damages, losses, and causes of action exceed the amount paid by you,
            if any, for accessing this Site or $100, whichever is lesser. You
            agree to bring any and all actions within one year from the date of
            the accrual of the cause of action, and that actions brought after
            this date will be barred.
          </p>
          <p>
            In the event that you have a dispute with Lift Credit, LLC, you
            release Lift Credit, LLC (and our officers, directors, agents, and
            employees) from claims, demands, and damages (actual and
            consequential) of every kind and nature, known and unknown,
            suspected and unsuspected, disclosed and undisclosed, arising out of
            or in any way connected with such disputes.
          </p>
          <h4>Links to Other Sites</h4>

          <p>
            LiftCredit.com may provide links to web sites not operated by Lift
            Credit, LLC. Access to any other sites linked to LiftCredit.com is
            at your own risk. We assume no responsibility for third-party web
            sites. For example, we do not vouch for the accuracy or reliability
            of the information on third-party web sites, even if someone from
            our company is quoted or leaves a comment. We assume no
            responsibility for the content of, or services offered by linked
            third party-sites, and make no representations regarding the
            accuracy of materials on third-party Web sites. Statements made on
            third-party Web sites linked to or from this site reflect only the
            views of their authors and not of Lift Credit, LLC.
          </p>
          <p>
            Unless otherwise set forth in a written agreement between you and
            Lift Credit, LLC, you may link your Web site to the home page of our
            Site—provided that you adhere to the following linking policy:
            <br />
            (1) any link to LiftCredit.com’s Site must be a text only link and
            clearly marked “LiftCredit.com Site,”
            <br />
            (2) the appearance, position and other aspects of the link may not
            be such as to damage or dilute the goodwill associated with
            LiftCredit.com’s name, trademarks, and certification marks,
            <br />
            (3) the link must “point” to the URL http://www.LiftCredit.com and
            not to other pages within our Site,
            <br />
            (4) the appearance, position and other attributes of the link may
            not create the false appearance that your organization or entity is
            sponsored by, affiliated with, or associated with Lift Credit, LLC
            or LiftCredit.com.
            <br />
            (5) when selected by a user, the link must display the
            LiftCredit.com Site on full-screen and not within a “frame” on the
            linking Site, and
            <br />
            (6) Lift Credit, LLC reserves the right to revoke its consent to the
            link at any time and in its sole discretion.
            <br />
          </p>
          <h4>Violations and Additional Terms</h4>
          <p>
            Lift Credit, LLC reserves the right to seek all remedies available
            at law and in equity for violations of this Terms and Conditions
            statement, including suspending or blocking your access to the Site.
            Please see our Privacy Policy for our policies regarding privacy,
            which is incorporated herein by reference.
          </p>
          <h4>No Waiver</h4>

          <p>
            No delay or failure by Lift Credit, LLC to enforce any of these
            Terms and Conditions shall constitute a waiver of any of our rights
            under these Terms and Conditions. Neither the receipt of any funds
            by Lift Credit, LLC nor the reliance of any person on our actions
            shall be deemed to constitute a waiver of any part of these Terms
            and Conditions. Only a specific, written waiver signed by an
            authorized representative of Lift Credit, LLC shall have any legal
            effect.
          </p>
          <h4>Severability</h4>

          <p>
            If any clause or provision set forth in this Terms and Conditions
            statement is determined to be illegal, invalid or unenforceable
            under present or future law, the clause or provision shall be deemed
            to be deleted without affecting the enforceability of all remaining
            clauses or provisions.
          </p>
          <h4>Governing Law and Jurisdiction</h4>
          <p>
            These Terms and Conditions and any disputes arising under or related
            to these Terms and Conditions and/or the Privacy Policy or to this
            Site will be governed by U.S. federal law and the laws of the State
            of Utah, without reference to its conflict of law principles. Any
            such dispute shall be resolved exclusively in the state or federal
            courts in Utah County, Utah, where we have our headquarters. You
            agree to submit to the personal jurisdiction and venue of the courts
            of the State of Utah for any legal proceeding involving the Site,
            regardless of who initiated the proceeding.
          </p>
          <p>
            This English-language Terms and Conditions statement is Lift Credit,
            LLC’s official agreement with users of this Site. In case of any
            inconsistency between this English-language Terms and Conditions
            statement and its translation into another language, this
            English-language document shall control.
          </p>
          <h4>For More Information</h4>
          <p>
            If you have any questions regarding our Terms and Conditions
            statement, please contact us by sending an email to
            support@liftcredit.com, calling our customer service at
            1-800-223-5858, or writing to us at Lift Credit, LLC, 3214 N.
            University Avenue, Box #601, Provo, UT 84604.
          </p>
          <h4>Changes to this Agreement</h4>
          <p>
            Lift Credit, LLC reserves the right, in its sole discretion, to
            modify, alter, or otherwise change this Agreement at any time. We
            will provide notice of such change on this Web site. Please review
            the Terms of Use and/or Additional Terms periodically for changes.
            Your continued use of the LiftCredit.com Web site constitutes your
            acceptance of and agreement to be bound by these changes without
            limitation, qualification or change. If at any time you determine
            that you do not accept these changes, you must stop using this Web
            site.
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default HelpIncomeModal;
