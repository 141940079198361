import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { getLoanHistory } from "../../services/loanPortalService";
import { Loan, LoanHistoryResponse } from "../../types/genericType";

const LoanHistory = () => {
  const [loans, setLoans] = useState<Loan[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLoans, setTotalLoans] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchLoans = async (page: number, perPage: number) => {
    setLoading(true);
    setError(null);
    try {
      const response: LoanHistoryResponse = await getLoanHistory(page, perPage);
      setLoans(response.resources?.filter((loan) => loan?.status) || []);
      setTotalLoans(response.count || 0);
    } catch (err) {
      console.error("Error fetching loan data:", err);
      setError("Failed to load loan data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoans(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaders = useMemo(
    () => ["Loan Number", "Status", "Origination Date", "Amount", "Interest Rate", "View"],
    []
  );

  const headerStyle = { backgroundColor: "lightgray", color: "#36454F" };
  const rowStyle = { backgroundColor: "#F5F5F5", color: "#36454F", align: "center" };

  return (
    <div>
      <Typography sx={{ fontSize: "12px", color: "#5a5a5b", mb: 2 }}>
        ACCOUNT
      </Typography>
      <Typography sx={{ fontSize: "20px", color: "#4caf50", mb: 4 }}>
        Loan History
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="loan history table">
              <TableHead sx={{ backgroundColor: "grey" }}>
                <TableCell colSpan={6} sx={{ color: "white" }}>
                  Loans
                </TableCell>
              </TableHead>

              <TableHead sx={{ ...headerStyle }}>
                <TableRow>
                  {tableHeaders.map((col) => (
                    <TableCell key={col} sx={{ color: headerStyle.color }}>
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loans.map((loan) => (
                  <TableRow key={loan.loanId} sx={{ ...rowStyle }}>
                    <TableCell>{loan.loanId}</TableCell>
                    <TableCell>{loan.status}</TableCell>
                    <TableCell>
                      {new Date(loan.originationDate).toLocaleDateString(
                        "en-GB",
                        {
                          day: "numeric",
                          month: "short",
                        }
                      )}
                    </TableCell>
                    <TableCell>US${loan.amount.toFixed(2)}</TableCell>
                    <TableCell>{loan.originalApr}%</TableCell>
                    <TableCell>
                      <Button color="success">Details</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalLoans}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </div>
  );
};

export default LoanHistory;
