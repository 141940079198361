import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { DisclaimersResponse, RewardsResponse } from "../types/genericType";

export const getRewards = async ({ perPage }: { perPage: string }) => {
  try {
    const response: AxiosResponse<RewardsResponse> = await axiosInstance.get(
      `${apiEndPoints.rewards}?perPage=${perPage}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getDisclaimers = async ({ perPage }: { perPage: string }) => {
  try {
    const response: AxiosResponse<DisclaimersResponse> =
      await axiosInstance.get(`${apiEndPoints.disclaimers}?perPage=${perPage}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsOrderSummary = async (skus: {}) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.summary}`,
      {
        skus,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const placeRewardOrder = async (orderData: {}) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.order}`,
      orderData
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsDetails = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.rewardSummary}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
