import { Box } from "@mui/material";
import TopBar from "../topBar/TopBar";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { useContext } from "react";
import { AuthContext } from "../../core/authContext";
import MenuComponent from "../menuComponent/MenuComponent";

const MainLayout = () => {
  const userDetails = useContext(AuthContext);

  return (
    <Box className="main-layout-container" sx={{ backgroundColor: "#f4f4f4" }}>
      <TopBar />
      {userDetails?.isLoggedIn && <MenuComponent userDetails={userDetails} />}
      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: userDetails?.isLoggedIn ? "0px" : "64px",
          padding: { xs: "0", sm: "0 32px", md: "0 64px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "950px",
            margin: {
              xs: "25px 0 0 0",
              sm: "25px 20px 0 20px",
              md: "25px 40px 0 40px",
            },
            padding: { xs: "20px", sm: "30px", md: "50px 92px" },
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;
