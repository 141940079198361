import React from "react";
import { Box } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      paddingX={4}
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        fontSize={24}
      >
        Something went wrong!! <br /> Brace yourself till we get the error fixed.<br />
        You may also refresh the page and try again later.
      </Box>
    </Box>
  );
};

export default ErrorPage;
