import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconBtn from "../IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { forgotPassword } from "../../services/authService";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError("Required field");
    } else if (!isValidEmail(email)) {
      setError("Invalid email");
    }

    if (!error) {
      const data = await forgotPassword(email);
      if (data) {
        setSuccess(true);
      }
    }
  };

  useEffect(() => {
    if (email) {
      setError("");
    }
  }, [email]);

  return (
    <Container>
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography
          variant="subtitle1"
          sx={{ color: "#00db8f", fontWeight: 500 }}
        >
          Portal Sign In Help
        </Typography>
        <Typography variant="body2" sx={{ color: "#838588" }}>
          Get Username and password help
        </Typography>
      </Box>
      {success ? (
        <>
          <Typography variant="body2" sx={{ color: "#838588" }}>
            Password reset mail send successfully.
          </Typography>
        </>
      ) : (
        <>
          <>
            <Typography
              variant="body1"
              sx={{ color: "#838588", fontWeight: "500", marginBottom: "10px" }}
            >
              Having trouble signing in?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <DoneIcon sx={{ color: "#00db8f", marginRight: "10px" }} />
              <Box>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Did you forget your username?
                </Typography>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Hint: It is the email address you provided when you signed up.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "15px",
              }}
            >
              <DoneIcon sx={{ color: "#00db8f", marginRight: "10px" }} />
              <Box>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Did you forget your password?
                </Typography>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Enter your username below, and we will email you a password
                  reminder:
                </Typography>
              </Box>
            </Box>
          </>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Email
              </Typography>
              <TextField
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Your Email Address"
                error={!!error}
                helperText={error}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={4}>
            <IconBtn
              icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
              handleBtnClick={handleResetPassword}
              title="Request Password"
              type="contained"
            />
          </Box>
        </>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={() => {
            navigate("/login");
          }}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Return to Login
        </Button>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
