import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import MainLayout from "./components/layout/MainLayout";
import LoanApplicationForm from "./pages/LoanApplicationForm";
import SorryPage from "./pages/SorryPage/SorryPage";
import LoanPortal from "./pages/LoanPortal/LoanPortal";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import MyProfile from "./pages/MyProfile/MyProfile";
import LoanHistory from "./pages/LoanHistory/LoanHistory";
import RedeemPage from "./pages/RedeemPage/RedeemPage";
import CartPage from "./pages/RedeemPage/CartPage";
import LoanAgreementViewer from "./pages/LoanAgreement/AgreementViewer";

type AppRouterProps = {
  isLoggedIn: boolean;
};

const AppRouter = ({ isLoggedIn }: AppRouterProps) => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/portal" /> : <Login />}
        />
        <Route
          path="/forgot-password"
          element={isLoggedIn ? <Navigate to="/portal" /> : <ForgotPassword />}
        />
        <Route path="/sorry" element={<SorryPage />} />
        <Route
          path="/portal"
          element={isLoggedIn ? <LoanPortal /> : <Navigate to="/login" />}
        />
      </Route>

      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={
            isLoggedIn ? <Navigate to="/portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/info"
          element={
            isLoggedIn ? <Navigate to="/portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/income"
          element={
            isLoggedIn ? <Navigate to="/portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/accounts"
          element={
            isLoggedIn ? <Navigate to="/portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/login"
          element={
            isLoggedIn ? <Navigate to="/portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/portal"
          element={isLoggedIn ? <LoanPortal /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={isLoggedIn ? <MyProfile /> : <Navigate to="/login" />}
        />
        <Route
          path="/loan-history"
          element={isLoggedIn ? <LoanHistory /> : <Navigate to="/login" />}
        />
        <Route
          path="/rewards"
          element={isLoggedIn ? <RedeemPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/rewards/cart"
          element={isLoggedIn ? <CartPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/agreement"
          element={isLoggedIn ? <LoanAgreementViewer /> : <Navigate to="/login" />}
        />
        <Route
          path="/apply"
          element={
            isLoggedIn ? <LoanApplicationForm /> : <Navigate to="/login" />
          }
        />
        <Route path="/sorry/:reason" element={<SorryPage />} />
      </Route>

      <Route
        path="*"
        element={<Navigate to={isLoggedIn ? "/portal" : "/login"} />}
      />
    </Routes>
  );
};

export default AppRouter;
