import React from "react";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";

interface DropdownOption {
  value: string;
  label: string;
}

interface DropdownProps {
  id: string;
  label: string;
  value: string;
  options: DropdownOption[];
  onChange: (value: string) => void;
  error?: string;
  required?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  label,
  value,
  options,
  onChange,
  error,
  required = false,
}) => {
  return (
    <FormControl fullWidth error={!!error}>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: "600",
          paddingLeft: "8px",
          marginBottom: "5px",
          color: "#838588",
        }}
      >
        {label || '\u00A0'}
      </Typography>
      <Select
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        size="small"
        displayEmpty
        required={required}
        sx={{
          "& .MuiSelect-select": {
            color: value === "" ? "#b0b0b0" : "inherit",
          },
          "& .MuiSelect-icon": {
            color: value === "" ? "#b0b0b0" : "inherit",
          },
        }}
      >
        <MenuItem value="">Choose One...</MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              "&:hover": {
                backgroundColor: "#007bff",
                color: "white",
              },
              "& .MuiListItemIcon-root": {
                display: value === option.value ? "none" : "inline",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default Dropdown;
